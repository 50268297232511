import * as Sentry from "@sentry/react";
import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import Select from "react-select";
import { GroupCodeCardHeader } from "./GroupCodeCardHeader";
import { GroupCodeCardItem } from "./GroupCodeCardItem";
import {
  getClustersByDomain,
  getDomainsByGradeLevel,
  getGrades,
  getNodeByStandardPrefix,
  getStandardsByCluster
} from "utils/standards";
import { colors, constants } from "utils";
import { request } from "hooks/useOkoApi/useOkoApi";

export const GroupCodeCard = ({ activeSessionQty,
                                activity,
                                code,
                                setParentIsLoading,
                                sessionLength,
                                standardPrefix,
                                standards,
                                triggerRefresh }) => {
  const [updatedActivity, setUpdatedActivity] = useState(activity);
  const initialGroupCodeItems = [
    {name: constants.ACTIVITY, options:
        [
          {value: constants.LETS_TALK, label: constants.LETS_TALK},
          {value: constants.CRAZY_CALCULATOR, label: constants.CRAZY_CALCULATOR}
        ],
      placeholder: constants.ACTIVITY_PLACEHOLDER,
    },
    {name: constants.GRADE_LEVEL, options: getGrades(standards), placeholder: constants.GRADE_LEVEL_PLACEHOLDER},
    {name: constants.DOMAIN, options: [], placeholder: constants.DOMAIN_PLACEHOLDER},
    {name: constants.CLUSTER, options: [], placeholder: constants.CLUSTER_PLACEHOLDER},
    {name: constants.STANDARD, options: [], placeholder: constants.STANDARD_PLACEHOLDER},
  ];

  // Logic for populating hierarchy for the previously saved prefix
  if (activity) {
    if (activity === constants.CRAZY_CALCULATOR_ENUM) {
      initialGroupCodeItems[0].placeholder = constants.CRAZY_CALCULATOR;
      initialGroupCodeItems[1].options = [];
    } else if (activity === constants.CONSENSUS_ENUM) {
      initialGroupCodeItems[0].placeholder = constants.LETS_TALK;
      initialGroupCodeItems[1].options = getGrades(standards);
      if (standardPrefix) {
        // We don't store standard prefixes for Crazy Calculator, hence all saved prefixes are for Let's Talk
        initialGroupCodeItems[0].placeholder = constants.LETS_TALK;
        const node = getNodeByStandardPrefix(standards, standardPrefix);
        if (node.grade) {
          initialGroupCodeItems[1].placeholder = node.grade;
          initialGroupCodeItems[2].options = getDomainsByGradeLevel(standards, node.grade);
          if (node.domain) {
            initialGroupCodeItems[2].placeholder = node.domain.name;
            initialGroupCodeItems[3].options = getClustersByDomain(standards, node.domain.prefix);
            if (node.cluster) {
              initialGroupCodeItems[3].placeholder = node.cluster.name;
              initialGroupCodeItems[4].options = getStandardsByCluster(standards, node.cluster.prefix);
              if (node.standard) {
                initialGroupCodeItems[4].placeholder = node.standard.name;
              }
            }
          }
        }
      }
    }
  };
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [groupCodeItems, setGroupCodeItems] = useState(initialGroupCodeItems);
  const [selectedNode, setSelectedNode] = useState(null);
  const [prevSelectedNode, setPrevSelectedNode] = useState(null);
  const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(true);
  const [updatedSessionLength, setUpdatedSessionLength] =  useState(sessionLength);

  if (selectedNode && JSON.stringify(prevSelectedNode) !== JSON.stringify(selectedNode)) {
    const newGroupCodeItems = JSON.parse(JSON.stringify(groupCodeItems));
    if (selectedNode.name === constants.ACTIVITY) {
      newGroupCodeItems[0].placeholder = selectedNode.value;
      newGroupCodeItems[1].placeholder = constants.GRADE_LEVEL_PLACEHOLDER;
      newGroupCodeItems[1].options = selectedNode.value === constants.LETS_TALK ? getGrades(standards) : [];
      newGroupCodeItems[2].placeholder = constants.DOMAIN_PLACEHOLDER;
      newGroupCodeItems[2].options = [];
      newGroupCodeItems[3].placeholder = constants.CLUSTER_PLACEHOLDER;
      newGroupCodeItems[3].options = [];
      newGroupCodeItems[4].placeholder = constants.STANDARD_PLACEHOLDER;
      newGroupCodeItems[4].options = [];
      setUpdatedActivity(selectedNode.value === constants.CRAZY_CALCULATOR ?
        constants.CRAZY_CALCULATOR_ENUM : constants.CONSENSUS_ENUM);
    } else if (selectedNode.name === constants.GRADE_LEVEL) {
      newGroupCodeItems[1].placeholder = selectedNode.value;
      newGroupCodeItems[2].placeholder = constants.DOMAIN_PLACEHOLDER;
      newGroupCodeItems[2].options = getDomainsByGradeLevel(standards, selectedNode.value);
      newGroupCodeItems[3].placeholder = constants.CLUSTER_PLACEHOLDER;
      newGroupCodeItems[3].options = [];
      newGroupCodeItems[4].placeholder = constants.STANDARD_PLACEHOLDER;
      newGroupCodeItems[4].options = [];
    } else if (selectedNode.name === constants.DOMAIN) {
      newGroupCodeItems[2].placeholder = selectedNode.label;
      newGroupCodeItems[3].placeholder = constants.CLUSTER_PLACEHOLDER;
      newGroupCodeItems[3].options = getClustersByDomain(standards, selectedNode.value);
      newGroupCodeItems[4].placeholder = constants.STANDARD_PLACEHOLDER;
      newGroupCodeItems[4].options = [];
    } else if (selectedNode.name === constants.CLUSTER) {
      newGroupCodeItems[3].placeholder = selectedNode.label;
      newGroupCodeItems[4].placeholder = constants.STANDARD_PLACEHOLDER;
      newGroupCodeItems[4].options = getStandardsByCluster(standards, selectedNode.value);
    } else if (selectedNode.name === constants.STANDARD) {
      newGroupCodeItems[4].placeholder = selectedNode.label;
    }
    setPrevSelectedNode(selectedNode);
    setGroupCodeItems(newGroupCodeItems);
    setIsUpdateButtonDisabled(false);
  }

  const handleSelectSessionDuration = (e) => {
    setUpdatedSessionLength(e.value);
    if (!selectedNode) {
      if (e.value !== sessionLength) {
        setIsUpdateButtonDisabled(false);
      } else {
        setIsUpdateButtonDisabled(true);
      }
    }
  };

  const getSessionDurationOptions = () => {
    return (
      [
        { label: "10 minutes", value: 10 },
        { label: "15 minutes", value: 15 },
        { label: "20 minutes", value: 20 },
        { label: "25 minutes", value: 25 },
        { label: "30 minutes", value: 30 },
      ]
    );
  };

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      borderColor: "#858B87",
      width: "150px"
    }),
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? colors.gray100 : undefined,
        padding: "8px 12px",
        color: "#1D1F23",
        ':active': {
          ...styles[':active'],
          backgroundColor: isSelected ? 'black' : 'white',
        },
      };
    },
    valueContainer: (styles) => ({
      ...styles,
      padding: "4px 6px" })
  };

  const handleUpdate = async () => {
    try {
      setIsLoading(true);
      setIsUpdateButtonDisabled(true);
      setParentIsLoading(true);
      await request(`group/${code}`, {
        payload: {
          config: {
            activity_type: updatedActivity,
            standard_prefix: selectedNode
              ? (selectedNode.name !== constants.ACTIVITY ? `${selectedNode.value}` : '')
              : standardPrefix,
            session_length: updatedSessionLength,
          }
        }
      });
      setIsLoading(false);
      setParentIsLoading(false);
      setSelectedNode(null);
      triggerRefresh(code);
    } catch (e) {
      setIsLoading(false);
      setParentIsLoading(false);
      setIsUpdateButtonDisabled(false);
      setError(e?.detail || e?.message || "An unknown error occurred.");
      Sentry.captureException(e);
    }
  };

  return (
    <div style={{
      backgroundColor: "white",
      borderRadius: "12px",
      boxShadow: "0px 1px 3px 0px rgba(14, 26, 49, 0.10), 0px 1px 2px 0px rgba(14, 26, 49, 0.06)",
      display: "flex",
      flexDirection: "column",
      gap: "24px",
      justifyContent: "space-between",
      padding: "24px",
      height: "537px",
      width: "428px" }}>
      <GroupCodeCardHeader
        activeSessionQty={activeSessionQty}
        activity={updatedActivity}
        groupCodeTitle={code.toUpperCase()}
        prefix={standardPrefix}
        standards={standards}>
      </GroupCodeCardHeader>
      <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        {groupCodeItems.map((item, index) => (
          <GroupCodeCardItem
            key={index}
            name={item.name}
            options={item.options}
            value={item.placeholder}
            setSelectedNode={setSelectedNode}>
          </GroupCodeCardItem>
        ))}
        <p style={{ color: (selectedNode?.value === constants.CRAZY_CALCULATOR ||
            (!selectedNode && activity === constants.CRAZY_CALCULATOR_ENUM))
            ? colors.nightlyWoods700
            : "white", fontSize: "10px" }}>
          {constants.CRAZY_CALC_GROUP_CODE_CARD_MESSAGE}
        </p>
        <div style={{ alignItems: "center", display: "flex", justifyContent: "space-between" }}>
          <span>Default Session Duration</span>
          <Select
            menuPlacement={"auto"}
            options={getSessionDurationOptions()}
            defaultValue={getSessionDurationOptions()[1]}
            onChange={handleSelectSessionDuration}
            styles={selectStyles}
            value={getSessionDurationOptions().filter((i) => updatedSessionLength === i.value)}>
          </Select>
        </div>
      </div>
      <button style={{
        backgroundColor: "white",
        border: "1px solid #858B87",
        borderRadius: "6px",
        color: colors.nightlyWoods500,
        display: "flex",
        justifyContent: "center",
        opacity: isUpdateButtonDisabled ? "0.5" : "1",
        padding: "12px 24px" }}
              disabled={isUpdateButtonDisabled || isLoading}
              onClick={handleUpdate}>
        Update
      </button>
      {error &&
        <Alert style={{ overflowWrap: "break-word" }}
               className="center-display"
               dismissible
               onClose={() => setError(null)}
               variant="danger">{error}
        </Alert>
      }
    </div>
  );
};
