import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { colors } from "utils";

const MessageBarContainer = styled.div`
  position: fixed;
  bottom: ${props => props.isVisible ? "0" : "-100px"};
  left: 0;
  right: 60px;
  background-color: ${colors.nightlyWoods500};
  color: ${colors.milkGlass500};
  padding: 15px;
  transition: bottom 0.3s ease-in-out;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 20px;
`;

const MessageText = styled.p`
  margin: 0;
  font-size: 16px;
  max-width: calc(100% - 40px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: ${colors.milkGlass500};
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
`;

export const MessageBar = ({ message }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (message) {
      setIsVisible(true);
      const timer = setTimeout(() => setIsVisible(false), 10000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!message) return null;

  return (
    <MessageBarContainer isVisible={isVisible}>
      <MessageText>{message}</MessageText>
      <CloseButton onClick={handleClose}>&times;</CloseButton>
    </MessageBarContainer>
  );
};
