import to from "await-to-js";
import { auth } from "hooks/useFirebase";

export const request = async (
  path,
  { method = "POST", headers = {}, payload } = {}
) => {
  const [error, token] = await to(auth.currentUser.getIdToken(true));

  if (error) console.error("useOkoApi Error", error);

  const endpoint = process.env.REACT_APP_OKOLABS_API_ENDPOINT;

  const allHeaders = {
    Authorization: `Bearer ${token}`,
    accept: "application/json",
    "Content-Type": "application/json",
    "ngrok-skip-browser-warning":"69420",
    ...headers,
  };

  const data = {
    headers: allHeaders,
    method,
  };

  if (payload) data["body"] = JSON.stringify(payload);

  return fetch(`${endpoint}/${path}`, data)
    .then(async (response) => {
      const contentType = response.headers.get("content-type");
      if (!response.ok) {
        const errorData = contentType?.includes("application/json")
          ? await response.json()
          : await response.text();
        return Promise.reject(errorData);
      }
      return contentType?.includes("application/json") ? await response.json() : response;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const useOkoApi = () => {
  return { fetch: request };
};
