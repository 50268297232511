export const texts = {
  createSessionTitle: `Welcome to OKO`,
  createSessionDescription: `Enter a your access code below to create a new session.`,
  createSessionButtonText: `Create Session`,
  joinSessionTitle: `Ready to join?`,
  joinSessionDescription: `Choose a method below to sign in.`,
  menuExplination: `Want to start a new activity? Click here to get started.`,
  waitingRoomExplanationDescriptiopn: `Participants in the room see the box below. Use the link to share this session.`,
  waitingRoomDefaultTitle: `OKO is preparing. Hold on a minute...`,
  waitingRoomDefaultSubtitle: `Once everything is ready the session will begin.`,
};
