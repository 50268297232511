import "./groupCode.css";
import React, { useEffect, useState } from "react";
import { Alert, Label, Input } from "@bootstrap-styled/v4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { request } from "hooks/useOkoApi/useOkoApi";
import { useFirebase } from "hooks/useFirebase";
import * as Sentry from "@sentry/react";
import logo from "assets/OKO_Core_Logotype2.svg";
import { colors } from "utils";
import { Form, Button, Card } from "react-bootstrap";
import { constants } from "utils";
import { useAppStateContext } from "hooks/useAppState/useAppState";
import { OKOFooter } from "../../OKOFooter";

export const GroupCode = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [code, setCode] = useState(new URLSearchParams(window.location.search).get("code"));
  const [name, setName] = useState();
  const firebase = useFirebase();
  const [authOptions, setAuthOptions] = useState();
  const { setGroupCode, setDisplayName, setSessionAuthType, setIsRecordSessions } = useAppStateContext();

  useEffect(async () => {
    if (code) {
      setIsLoading(true);
      await handleGroupCode();
      setIsLoading(false);
    }
  }, []);

  const handleDisplayName = async (e) => {
    e?.preventDefault();
    setError(null);
    if (!name) {
      setError({ detail: "Name can't be empty!"});
      return;
    }
    setIsLoading(true);
    setDisplayName(name);
    setSessionAuthType(constants.ANONYMOUS);
  };
  const handleGroupCode = async (e) => {
    e?.preventDefault();
    setError(null);
    if (!code) {
      setError({ detail: "Group code can't be empty!"});
      return;
    }
    setGroupCode(code);
    await request(`group/${code}/settings`, { method: "GET" })
      .then( async (authOptionsResponse) => {
        setAuthOptions(authOptionsResponse?.auth_methods);
        setIsRecordSessions(authOptionsResponse?.record_sessions);
        if (authOptionsResponse?.auth_methods.includes(constants.GOOGLE)) {
          setIsLoading(true);
          const provider = new firebase.auth.GoogleAuthProvider();
          // Auth needs to happen inside the click handler to prevent blocking the pop-up by the browser
          await firebase
            .auth()
            .signInWithPopup(provider)
            .then( async (result) => {
              setSessionAuthType(constants.GOOGLE);
            })
            .catch((e) => {
              setIsLoading(false);
              Sentry.captureException(e)
              setError(e);
            });
        }
      }).catch((e) => {
        Sentry.captureException(e);
        setError(e);
      });
  };

  const labelStyle = { color: "#1D1F23", lineHeight: "20px" };
  const inputStyle = { fontSize: "16px", padding: "0 12px" };
  const buttonStyle = {
    backgroundColor: colors.pink500,
    border: "none",
    borderRadius: "20px",
    fontSize: "16px",
    height: "48px" };

  return (
    isLoading ? (
      <div className="center-display">
        <FontAwesomeIcon
          icon={faSpinner}
          spin
          size="5x"
        />
      </div>
      ) : (
      <div style={{ display: "flex", alignItems: "center", maxHeight: "100vh", maxWidth: "100vw" }}>
        <div className="login-decoration login-decoration-portrait">
        </div>
        <Card className="login-card login-card-small-width">
          <div style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            gap: "32px",
            padding: "32px" }}>
            <img src={logo} alt="logo" style={{ width: "196px" }} />
            <>
              {!authOptions && <Form className="drop-in" onSubmit={handleGroupCode}>
                <Label className="mb-1" style={labelStyle}>
                  Group Code
                </Label>
                <Input
                  autoFocus
                  placeholder="Enter your group code"
                  size="lg"
                  style={inputStyle}
                  value={code || ""}
                  onChange={(e) => setCode(e.target.value)}
                />
                <Button
                  type="submit"
                  size="lg"
                  className=" w-100 mt-4"
                  style={buttonStyle}>
                  Submit <FontAwesomeIcon icon={faSignInAlt} className="ml-2" />
                </Button>
              </Form>}
              {authOptions?.includes(constants.ANONYMOUS) && <Form className="drop-in" onSubmit={handleDisplayName}>
                <Label className="mb-1" style={labelStyle}>
                  Name
                </Label>
                <Input
                  autoFocus
                  maxLength={32}
                  placeholder="Enter your name"
                  size="lg"
                  style={inputStyle}
                  value={name || ""}
                  onChange={(e) => setName(e.target.value)}
                />
                <p style={{ fontSize: "12px", marginTop: "20px" }}>
                  Note: Your Teacher is able to see the display name you set.
                </p>
                <Button
                  type="submit"
                  size="lg"
                  className="w-100 mt-4"
                  style={buttonStyle}>
                  Join <FontAwesomeIcon icon={faSignInAlt} className="ml-2" />
                </Button>
              </Form>}
            </>
            {error && (
              <Alert color="danger">
                {error?.detail || error?.message || "An unknown error occurred."}
              </Alert>
            )}
          </div>
        </Card>
        <OKOFooter />
      </div>
    )
  );
};
