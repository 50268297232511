import "./settingsModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  Form,
  Modal,
  InputGroup
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { generateRoutePath } from "utils/generateRoutePath";
import { request } from "hooks/useOkoApi/useOkoApi";
import * as Sentry from "@sentry/react";
import Select, { components } from "react-select";
import Clear from "../../../../assets/Clear.svg";
import { colors, constants, DOMAIN_REGEX, FORBIDDEN_EMAIL_DOMAIN_CHARS } from "utils";
import OKOCoreLogo from "../../../../assets/OKO_Core_Logotype2.svg";

export const SettingsModal = ({ isOpen, isOnboarding, setIsOpen }) => {
  const mountedRef = useRef(true);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [formData, setFormData] = useState({
    school_name: "",
    grade_levels: [],
    participant_email_domain_filter: "",
  });
  const alertStyle = {
    padding: "8px",
  }

  const getEducatorAccountData = async () => {
    setIsLoading(true);
    try {
      const educatorData = await request("account/educator", {
        method: "GET",
      });
      if (mountedRef.current) setFormData(educatorData.config);
      setIsLoading(false);
    } catch (e) {
      Sentry.captureException(e);
      setIsLoading(false);
      setError(e?.detail || e?.message || "An unknown error occurred.");
    }
  };

  useEffect(() => {
    getEducatorAccountData();
    return () => {
      mountedRef.current = false;
    }
  }, []);

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "participant_email_domain_filter") {
      value = value.replace(FORBIDDEN_EMAIL_DOMAIN_CHARS, "");
      // Allow only one "."
      const dotCount = (value.match(/\./g) || []).length;
      if (dotCount > 1) {
        value = value.slice(0, value.lastIndexOf("."));
      }
    }
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectGradeChange = (e) => {
    const value = e.map((i) => i.value);
    setFormData((prevState) => ({
      ...prevState,
      ["grade_levels"]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!DOMAIN_REGEX.test(formData.participant_email_domain_filter)) {
      setError({participant_email_domain_filter: "Make sure the email domain meets the following requirements:\n" +
          "Starts and ends with a letter or digit.\n" +
          "Allows hyphens (-) but not at the start or end.\n" +
          "Requires at least one . with a valid TLD (.com, .net, .org)."})
      return;
    }
    setIsLoading(true);
    try {
      await request("account/educator", {
        payload: {
          config: formData,
        },
      }).then(() => {
        setIsLoading(false);
        setIsOpen(false);
        if (isOnboarding) {
          history.push(generateRoutePath(constants.EDUCATOR_ROUTE_NAME));
        }
      });
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
      const errors = {};
      e.detail.forEach((error) => {
        errors[error.loc.pop()] = error.msg;
      });
      setError(errors);
      setIsLoading(false);
    }
  };

  const getGradeOptions = () => {
    return (
      [
        { label: "Kindergarten", value: "K" },
        { label: "1st Grade", value: "1" },
        { label: "2nd Grade", value: "2" },
        { label: "3rd Grade", value: "3" },
        { label: "4th Grade", value: "4" },
        { label: "5th Grade", value: "5" },
        { label: "6th Grade", value: "6" },
        { label: "7th Grade", value: "7" },
        { label: "8th Grade", value: "8" },
        { label: "9th Grade", value: "9" },
        { label: "10th Grade", value: "10" },
        { label: "11th Grade", value: "11" },
        { label: "12th Grade", value: "12" },
      ]
    );
  };

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      borderColor: "#ABB1BA" }),
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? colors.gray100 : undefined,
        color: "#1D1F23",
        ':active': {
          ...styles[':active'],
          backgroundColor: isSelected ? 'black' : 'white',
        },
      };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: colors.nightlyWoods500,
        borderRadius: "8px",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "20px",
        padding: "8px 16px",
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: colors.gray100,
      padding: `0 !important`,
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      paddingRight: `0 !important`,
      ':hover': {
        opacity: 0.9,
      },
    }),
  };

  const MultiValueRemove = (props) => {
    return (
      <components.MultiValueRemove {...props}>
        <img src={Clear} />
      </components.MultiValueRemove>
    );
  };

  const saveButton = (width) =>
    <Button style={{
      ...width,
      backgroundColor: colors.nightlyWoods500,
      border: "none",
      padding: "12px 24px" }}
            type="submit">
      Save
    </Button>;

  const handleClose = () => {
    // The if condition prevents clicking outside or using ESC button for closing the Modal
    // even when the closeButton is hidden
    if (!isOnboarding) setIsOpen(false);
  };

  const buttons = () =>
    <div style={{ display: "flex", gap: "24px", justifyContent: "space-between" }}>
      <Button onClick={handleClose} style={{
        backgroundColor: colors.neutral300,
        border: "none",
        padding: "12px 24px",
        width: "100%" }} >
        Cancel
      </Button>
      <>{saveButton({ width: "100%"})}</>
    </div>;

  return (
    <Modal className="settings-modal" show={isOpen} onHide={handleClose}>
      {isLoading && <div style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 9999
      }}>
        <FontAwesomeIcon
          icon={faSpinner}
          spin
          size="5x"
        />
      </div>}
      <Modal.Header style={{
        border: "none",
        opacity: isLoading ? 0.5 : 1,
        padding: 0,
        position: "absolute",
        right: "32px" }} closeButton={!isOnboarding} />
      <Form onSubmit={handleSubmit}>
        <div style={{ display: "flex", flexDirection: "column" , gap: "32px", opacity: isLoading ? 0.5 : 1 }}>
          <img src={OKOCoreLogo} style={{ alignSelf: "center", width: "196px" }} />
          <div style={{ display: "flex", flexDirection: "column" , gap: "24px" }}>
            <p style={{
              fontSize: "28px",
              fontWeight: 700,
              lineHeight: "36px" }}>
              Educator Settings
            </p>
            <Form.Group>
              <Form.Label style={{ fontWeight: "bold" }}>School Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter school name"
                value={formData.school_name}
                name="school_name"
                onChange={handleChange}
              />
              {error?.school_name && (
                <Alert style={alertStyle} variant="danger">{error.school_name}</Alert>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label style={{ fontWeight: "bold" }}>Grade Selections</Form.Label>
              <Select
                components={{ MultiValueRemove }}
                isMulti
                options={getGradeOptions()}
                onChange={handleSelectGradeChange}
                placeholder="Select grades"
                styles={selectStyles}
                value={getGradeOptions().filter((i) => formData.grade_levels.includes(i.value))}>
              </Select>
              {error?.grade_levels && (
                <Alert style={alertStyle} variant="danger">{error.grade_levels}</Alert>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label style={{ fontWeight: "bold" }}>
                Student Email Format
              </Form.Label>
              <InputGroup>
                <InputGroup.Text style={{ color: colors.neutral200 }}>studentname@</InputGroup.Text>
                <Form.Control
                  required
                  type="text"
                  name="participant_email_domain_filter"
                  placeholder="domain.com"
                  value={formData.participant_email_domain_filter}
                  onChange={handleChange}
                />
              </InputGroup>
              {error?.participant_email_domain_filter && (
                <Alert style={alertStyle} variant="danger">
                  {error.participant_email_domain_filter}
                </Alert>
              )}
            </Form.Group>
          </div>
          {isOnboarding ? (<>{saveButton()}</>) : (<>{buttons()}</>)}
        </div>
      </Form>
    </Modal>
  );
};
