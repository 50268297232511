import styled from "styled-components";

import { useSessionContext } from "hooks/useSession/useSession";
import { useMembers } from "hooks/livekit/useMembers/useMembers";

import { GestureVideo } from "components/pages/Session/shared/GestureVideo/GestureVideo";
import { useActivity } from "hooks/useActivity/useActivity";
import { OkoAssistant } from "../../../OkoLesson/OkoAssistant/OkoAssistant";

export const MeetActivity = () => {
  const { session } = useSessionContext();
  const { members } = useMembers();
  const { activity } = useActivity();

  const disableGestureOverlay = !!activity?.disableGestureOverlay;

  let videoWidth = "30vw";

  if (members.length > 3) videoWidth = "28vw";
  if (members.length > 6) videoWidth = "20vw";
  if (members.length > 8) videoWidth = "16vw";

  const firstMemberIsSessionCreator =
    members?.[0]?.userId === session.creatorId;

  return (
    <>
      <OkoAssistant />
      <Wrapper>
        {firstMemberIsSessionCreator && (
          <Member
            key={members[0].userId}
            member={members[0]}
            width={videoWidth}
            style={{ marginBottom: 16 }}
            disableGestureOverlay={disableGestureOverlay}
          />
        )}

        <Videos>
          {members.slice(firstMemberIsSessionCreator ? 1 : 0).map((member) => (
            <Member
              key={member.userId}
              member={member}
              style={{ padding: 8 }}
              width={videoWidth}
              disableGestureOverlay={disableGestureOverlay}
            />
          ))}
        </Videos>
      </Wrapper>
    </>
  );
};

const Member = ({
  member: { participant, isLocalParticipant },
  width,
  style,
  disableGestureOverlay,
}) => {
  return (
    <div style={{ ...style, width, overflow: "hidden" }}>
      <GestureVideo
        participant={participant}
        isLocalParticipant={isLocalParticipant}
        disable={disableGestureOverlay}
      />
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: fixed;
`;

const Videos = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
  flex-wrap: wrap;
  width: 100%;
`;
