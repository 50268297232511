import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import OkoAniMouth from "assets/OKO_AniMouth_HigherRez.gif";
import OKOCoreLogo from "assets/OKO_Core_Logotype2.svg";
import OKOCharacter from "assets/OKO_op2_yellow.svg";
import OKOCharacterNoMouth from "assets/OKO_op2_yellow2.svg";
import { useDataTrack } from "hooks/livekit/useDataTrack/useDataTrack";

export const OkoAssistant = () => {
  const [phrase, setPhrase] = useState("");
  const [isPlayingAudio, setIsPlayingAudio] = useState(false);
  const [showText, setShowText] = useState(false);

  useDataTrack((e) => {
    if (e.event_type === "speech_start") {
      setIsPlayingAudio(true);
      setShowText(true);
      setPhrase(stripTags(e.data.message));
    }
    if (e.event_type === "speech_end") {
      setIsPlayingAudio(false);
      setShowText(phrase);

      setTimeout(() => {
        if (showText === phrase) setShowText(false);
      }, 8000);
    }
  });

  const okoCharacter = isPlayingAudio ? OKOCharacterNoMouth : OKOCharacter;
  const assistantIcon =
  <Icon>
    <img src={okoCharacter} style={{ height: "60px" }} alt="character" />
    <div
      style={{
        backgroundImage: `url(${
          isPlayingAudio ? OkoAniMouth : "none"
        })`,
        width: 34,
        height: 34,
        left: 20,
        top: 19,
        borderRadius: 100,
        position: "absolute",
        backgroundSize: "cover",
      }}
    ></div>
  </Icon>;

  return (
    <div style={{ display: "flex", flexDirection: "column", minHeight: "275px", width: "317px", gap: "24px" }}>
      <div style={{ display: "flex", position: "absolute", left: "24px", top: "24px", gap: "16px" }}>
        {assistantIcon}
        <img src={OKOCoreLogo} style={{ alignSelf: "center", width: "196px" }}  alt="logo" />
      </div>
      {phrase && showText && (
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "24px",
          marginTop: "108px",
          marginLeft: "24px",
          width: "100%",
          borderRadius: "0px 32px 32px 32px",
          background: "#FEEAE7"
        }}
        >
          <AnimatePresence>
            <TextContainer
              transition={{
                delay: 0.5,
              }}
              initial={{ width: 0, whiteSpace: "no-wrap" }}
              animate={{ width: "100%", whiteSpace: "normal" }}
            >
              <div style={{ fontSize: "16px", lineHeight: "normal" }}>{trimPhrase(phrase)}</div>
            </TextContainer>
          </AnimatePresence>
        </div>
      )}
    </div>
  );
};

function stripTags(str) {
  if (str === null || str === "") return false;
  else str = str.toString();
  return str.replace(/<[^>]*>/g, "");
}

// The following function is needed as "text-overflow: ellipsis" works only with "white-space: no-wrap" in CSS.
// Adding the latter results in Oko Assistant phrase being limited to 1 line.
function trimPhrase(str) {
  const maxLength = 230;
  return str.length > maxLength ? str.substring(0, maxLength - 3) + "..." : str.substring(0, maxLength);
}

const Icon = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 70px;
  height: 60px;
`;

const TextContainer = styled(motion.div)`
  align-self: normal;
  line-height: 1;
  overflow: hidden;
  font-size: 30px;
  border-radius: 0.25rem;

  > div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #002E19;
  }
`;
