import {
  faEyeSlash,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { ActionButton } from "./ActionButton";
import { useSessionContext } from "hooks/useSession";

export const ToggleSessionInspectorButton = ({ style }) => {
  const { isShowSessionInspector, toggleSessionInspector } = useSessionContext();
  return (
    <ActionButton
      onClick={toggleSessionInspector}
      style={style}
      icon={isShowSessionInspector ? faEye : faEyeSlash}
      active={isShowSessionInspector}
      tooltip={!isShowSessionInspector ? "Show Session Inspector" : "Hide Session Inspector"}
    />
  );
};
