import React from "react";
import { OkoAssistant } from "../../../OkoLesson/OkoAssistant/OkoAssistant";
import { ConsensusIndicators } from "./ConsensusIndicators";

export const ConsensusLeftPanel = ({ isSolutionEvent, isChallengeSolved, timer }) => {
  return(
    <div style={{
      display: "flex",
      flexDirection: "column",
      position: "fixed",
      height: "100vh",
      left: 0,
      top: 0,
      width: "405px" }}>
      <div>
        <OkoAssistant />
      </div>
      <div style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        gap: "24px",
        marginLeft: "24px",
        placeContent: "center",
        placeSelf: "flex-start" }}>
        <ConsensusIndicators isSolutionEvent={isSolutionEvent} isChallengeSolved={isChallengeSolved} />
        {timer}
      </div>
    </div>
  );
};
