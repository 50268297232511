import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Input } from "@bootstrap-styled/v4";
import * as Sentry from "@sentry/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faStar as solidStar, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import { Button, Modal } from "react-bootstrap";
import { useFirebase } from "hooks/useFirebase";
import { request } from "hooks/useOkoApi/useOkoApi";
import { useDataTrack } from "hooks/livekit/useDataTrack/useDataTrack";
import { ActionBar } from "../shared/ActionBar/ActionBar";
import { NotFound } from "./activities/NotFound/NotFound";
import { useSessionActivity } from "hooks/useSessionActivity/useSessionActivity";
import { SessionInspector } from "../SessionInspector";
import { MessageBar } from './MessageBar';
import { NetworkMonitor } from "./NetworkMonitor";
import { colors } from "utils";

export const Activity = ({ disable }) => {
  const { activity, isShowSessionInspector, toggleSessionInspector, session } = useSessionActivity();
  const [isSuperUser, setIsSuperUser] = useState(true);
  const [message, setMessage] = useState("");

  useEffect(async () => {
    await request(`account/is_super_user`, { method: "GET" })
      .then((response) => setIsSuperUser(response))
      .catch((e) => {
        console.log(e);
        Sentry.captureException(e);
      });
  }, []);

  const Activity = activity?.component;
  let activityStyle = {};
  if (activity?.id === "calculator") {
    activityStyle = {...activityStyle, display: "contents" };
  }

  const updateMessage = (newMessage) => {
    setMessage(newMessage);
  };

  return (
    <Container
      className="fullscreen"
      style={{
        overflowY: "hidden",
        pointerEvents: disable ? "none" : "auto",
      }}
    >
      <div style={activityStyle}>
        {Activity ? <Activity /> : <NotFound />}
      </div>
      <Leave session={session}></Leave>
      <MessageBar message={message} />
      <VideoActionBarWrapper>
        <ActionBar isShowSessionInspectorButton={isSuperUser} />
      </VideoActionBarWrapper>
      <NetworkMonitor style={{ position: "absolute", bottom: "10px", right: "10px" }} updateMessage={updateMessage} />
      {isSuperUser && isShowSessionInspector && <SessionInspector toggleSessionInspector={toggleSessionInspector} />}
    </Container>
  );
};

const Container = styled.div`
  background: #edf2f9;
  display: flex;
  position: relative;
`;

const VideoActionBarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: calc(50% - 184px / 2);
  right: 0;
  padding: 24px;
`;

const LeaveButton = styled(Button)`
  right: 24px;
  top: 24px;
  width: 40px;
  height: 40px;
  padding: 0px !important;
  position: absolute;
  border: 1px ${colors.nightlyWoods950} solid !important;
  border-radius: 100px !important;
  color: ${colors.nightlyWoods950} !important;
  background: white !important;
  box-shadow: none !important;
`;

const Leave = ({ session }) => {
  const firebase = useFirebase();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSurveyDisplayFlagUpdated, setIsSurveyDisplayFlagUpdated] = useState(false);
  const [starRating, setStarRating] = useState();
  const [detailsText, setDetailsText] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isSessionEnd, setIsSessionEnd] = useState(false);

  useDataTrack(async (e) => {
    if (e.event_type === "state_change" && e.data?.state === "complete") {
      setIsModalOpen(true);
      setIsSessionEnd(true);
      if (!isSurveyDisplayFlagUpdated && session?.id) {
        await request(`feedback/${session?.id}/survey_displayed`, {})
          .then(() => setIsSurveyDisplayFlagUpdated(true))
          .catch((e) => {
            console.log(e);
            Sentry.captureException(e);
          });
      }
      setTimeout(() => onLeave(), 300000);
    }
  });

  const handleClose = () => {
    if (isLoading) return;
    setIsModalOpen(false);
  };

  const onLeave = async () => {
    if (isLoading) return;
    if (starRating) {
      setIsLoading(true);
      await request(`feedback/${session.id}/survey`, {
        payload: {
          star_rating: starRating,
          details: detailsText
        },
      }).then(() => setIsLoading(false))
        .catch((e) => {
          setIsLoading(false);
          console.log(e);
          Sentry.captureException(e);
        });
    }
    await firebase.auth().signOut();
    window.location.href = "https://okolabs.ai/";
  };

  const modalHeader = isSessionEnd ? "Session Survey" : "Exit Session?";
  const exitButtonText = isSessionEnd ? "Submit Survey" : "Exit";

  return (
    <>
      <LeaveButton
        onClick={ async () => {
          setIsModalOpen(true);
          if (!isSurveyDisplayFlagUpdated && session?.id) {
            await request(`feedback/${session.id}/survey_displayed`, {})
              .then(() => setIsSurveyDisplayFlagUpdated(true))
              .catch((e) => {
                console.log(e);
                Sentry.captureException(e);
              });
          }
        }}
      >
        <FontAwesomeIcon size="lg" icon={faTimes} />
      </LeaveButton>
      <Modal show={isModalOpen}>
        {isLoading && <div style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 9999
        }}>
          <FontAwesomeIcon
            icon={faSpinner}
            spin
            size="5x"
          />
        </div>}
        <Modal.Header><span style={{ fontSize: "20px" }}>{modalHeader}</span></Modal.Header>
        <Modal.Body>
          Please rate this session from 1 to 5 stars:
          <div style={{ display: "flex", flexDirection: "column", gap: "16px", marginTop: "16px" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {[1, 2, 3, 4, 5].map((rating) => (
                <FontAwesomeIcon
                  key={rating}
                  size="lg"
                  icon={rating <= starRating ? solidStar : faStar}
                  onClick={() => setStarRating(rating)}
                  style={{ color: rating <= starRating ? "#FFD700" : "#CCC", cursor: "pointer" }}
                />
              ))}
            </div>
            {starRating && <Input placeholder="Optional comments"
                                  maxLength={500}
                                  type="textarea"
                                  style={{ height: "100px" }}
                                  onChange={(e) => setDetailsText(e.target.value)}
                                  value={detailsText} />}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              onLeave();
            }}
            style={{
              backgroundColor: colors.pink500,
              border: "none",
              borderRadius: "20px",
              fontSize: "16px",
              cursor: isLoading ? "not-allowed" : "pointer",
              opacity: isLoading ? 0.5 : 1
              }}
          >
            {exitButtonText}
          </Button>
          {!isSessionEnd && <Button onClick={handleClose}
                  style={{
                    backgroundColor: "white",
                    border: `1px solid ${colors.nightlyWoods500}`,
                    borderRadius: "20px",
                    color: colors.nightlyWoods500,
                    fontSize: "16px",
                  }}
          >
            Cancel
          </Button>}
        </Modal.Footer>
      </Modal>
    </>
  );
};
