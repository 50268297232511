import { Card, Form } from "react-bootstrap";
import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { colors } from "utils";

export const LLMEventItem = forwardRef(({
                                          adjustTextArea,
                                          content,
                                          name,
                                          role,
                                          index,
                                          setLlmEventItem
                                        }, ref) => {
  const [newName, setNewName] = useState(name);
  const [newContent, setNewContent] = useState(content);
  const textareaRef = useRef(content);
  const [isOpen, setIsOpen] = useState(true);

  useImperativeHandle(ref, () => ({
    adjustTextArea: () => {
      requestAnimationFrame(() => {
        adjustTextAreaHeight();
      });
    }
  }));

  const adjustTextAreaHeight = () => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleContentChange = (e) => {
    setNewContent(e.target.value);
    setLlmEventItem(index, e.target.value, newName, role);
    adjustTextAreaHeight();
  };

  const handleNameChange = (e) => {
    setNewName(e.target.value);
    setLlmEventItem(index, newContent, e.target.value, role);
  };

  const handleToggle = () => {
    adjustTextArea();
    setIsOpen(prev => !prev);
  };

  return (
    <Card
      key={index}
      style={{ border: `1px solid ${colors.neutral300}`, borderRadius: "12px" }}>
      <Card.Header onClick={handleToggle} style={{ borderBottom: isOpen ? `1px solid ${colors.neutral300}` : "none", cursor: "pointer" }}>
        <div style={{ display: "flex", flexDirection: "row", gap: "24px" }}>
          <p style={{ fontSize: "18px", fontWeight: 700, lineHeight: "26px" }}>
            {isOpen ? "Click to hide AI look for metadata..." :
              "Click to expand and view AI look for metadata..."}
          </p>
        </div>
      </Card.Header>
      {isOpen && <Card.Body>
        <div style={{ display: "flex", flexDirection: "column", gap: "32px" }}>
          <Form.Group style={{ width: "100%" }}>
            <Form.Label style={{ fontWeight: "bold" }}>Content (Timestamp and Speech)</Form.Label>
            <Form.Control
              ref={textareaRef}
              as="textarea"
              required
              type="text"
              placeholder="Enter Content"
              value={newContent}
              onChange={handleContentChange}
            />
          </Form.Group>
          <Form.Group style={{ width: "100%" }}>
            <Form.Label style={{ fontWeight: "bold" }}>User Id</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Enter User Id"
              value={newName}
              onChange={handleNameChange}
            />
          </Form.Group>
        </div>
      </Card.Body>}
    </Card>
  );
});
