import "../consensusActivity.css"
import * as Sentry from "@sentry/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faEdit, faRobot, faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Input, Label} from "@bootstrap-styled/v4";
import React, {useCallback, useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { Button, Modal } from "react-bootstrap";
import { request } from "hooks/useOkoApi/useOkoApi";
import { useAuthUserData } from "hooks/useAuthUserData/useAuthUserData";
import { useStandards } from "hooks/useStandards";
import { buildSelectOptions} from "utils/standards";
import { buildPreviewChallenge, CHALLENGE_STATUS_OPTIONS } from "utils/challenge";
import { EditConsensusQuestionModal } from "./EditConsensusQuestionModal";
import { ConsensusActivityRender } from "../ConsensusActivityRender";
import { CHALLENGE_QUALITY_LLM_PROMPT, colors, CONSENSUS_VARIANT_OPTIONS, constants, generateRoutePath } from "utils";

export const ConsensusQuestionRender = () => {
  const DELETE_BATCH_SIZE = 25;
  const { authUserData, loading } = useAuthUserData();
  const history = useHistory();
  const standardsData = useStandards();
  const [questionsData, setQuestionsData] = useState([]);
  const [prefix, setPrefix] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [challengeToEdit, setChallengeToEdit] = useState();
  const [selectedChallengeType, setSelectedChallengeType] = useState("multiplechoice");
  const [selectedChallengeStatus, setSelectedChallengeStatus] = useState("all");
  const [deleteList, setDeleteList] = useState([]);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [deleteAPIResponse, setDeleteAPIResponse] = useState();
  const svgCross = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="40" height="40">
    <path fill="red" d="M6 6 L18 18 M18 6 L6 18" stroke="red" stroke-width="2" stroke-linecap="round" opacity="0.75"/>
  </svg>`;
  const svgCrossDataURL = `data:image/svg+xml;utf8,${encodeURIComponent(svgCross)}`;
  const [needsRefresh, setNeedsRefresh] = useState(false);
  const [llmList, setLlmList] = useState([]);
  const [llmResponse, setLlmResponse] = useState();
  const [isShowLLMModal, setIsShowLLMModal] = useState(false);
  const [llmPrompt, setLlmPrompt] = useState(CHALLENGE_QUALITY_LLM_PROMPT);
  const [llmBatchProgress, setLlmBatchProgress] = useState();
  const [llmBatchSize, setLlmBatchSize] = useState(50);

  useEffect(() => {
    if (needsRefresh) {
      getQuestionsData(prefix, selectedChallengeType);
      setNeedsRefresh(false);
    }
  }, [needsRefresh]);

  const getQuestionsData = async (prefix, selectedFormat) => {
    try {
      setIsLoading(true);
      let fetch_inactive = true;
      if (selectedChallengeStatus === "active") {
        fetch_inactive = false;
      }
      let questionsData = await request(
        `questions/fetch/${selectedFormat}?fetch_inactive=${fetch_inactive}${prefix ? `&standard_prefix=${prefix}` : ''}`, {
          method: "GET",
        });
      if (selectedChallengeStatus === "inactive") {
        questionsData = questionsData.filter((i) => i.status === "inactive");
      }
      setQuestionsData(questionsData);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      Sentry.captureException(e);
    }
  };
  const handleOnStandardsChange = (target) => {
    if (prefix === target.value) {
      return;
    }
    setPrefix(target.value);
    getQuestionsData(target.value, selectedChallengeType);
  };
  const handleOnFormatChange = (target) => {
    if (selectedChallengeType === target.value) {
      return;
    }
    setSelectedChallengeType(target.value);
    getQuestionsData(prefix, target.value);
  };
  const handleOnStatusChange = (target) => {
    if (selectedChallengeStatus === target.value) {
      return;
    }
    setSelectedChallengeStatus(target.value);
    setNeedsRefresh(true);
  };
  const getOptions = () => {
    const options = buildSelectOptions(standardsData);
    options.unshift({"value": null, label: "All available standards"});
    return options;
  };
  const onEdit = (challenge) => {
    setChallengeToEdit(challenge);
    setIsModalOpen(true);
  };
  const onAddToDeleteList = (challenge) => {
    setDeleteList(prevList => [...prevList, challenge])
  }
  const onRemoveFromDeleteList = (challenge) => {
    setDeleteList(prevList => prevList.filter(item => item.id !== challenge.id));
  };
  const onToggleDeleteModal = () => {
    setDeleteAPIResponse(null);
    setIsShowDeleteModal(prevState => !prevState);
  }
  const onAddToLLMList = (challenge) => {
    setLlmList(prevList => [...prevList, challenge])
  }
  const onRemoveFromLLMList = (challenge) => {
    setLlmList(prevList => prevList.filter(item => item.id !== challenge.id));
  };
  const onToggleLLMModal = () => {
    setLlmResponse(null);
    setIsShowLLMModal(prevState => !prevState);
  }

  const onDeleteChallenges = async () => {
    if (!deleteList.length) return;
    try {
      setIsLoading(true);
      const deleteBatches = chunkArray(deleteList, DELETE_BATCH_SIZE);
      let allResponses = "";
      for (let i = 0; i < deleteBatches.length; i++) {
        const batchProgress = `Deleting ${(i + 1) * DELETE_BATCH_SIZE < deleteList.length
          ? (i + 1) * DELETE_BATCH_SIZE : deleteList.length} messages out of ${deleteList.length}...`;
        console.log(batchProgress);
        const response = await request(
          "questions/delete",{
            method: "DELETE",
            payload: deleteBatches[i]
          });
        if (typeof response === "string") {
          allResponses += `Batch #${i + 1}: ${response}\n`;
        } else {
          allResponses += `Batch #${i + 1}: Some errors have occurred: "`
            + JSON.stringify(response) + "\n";
        }
        setDeleteAPIResponse(allResponses);
        // BE API sometimes returns 502 error if there is no gap between requests
        if (i < deleteBatches.length - 1) {
          await new Promise(resolve => setTimeout(resolve, 100));
        }
      }
      setDeleteList([]);
      setLlmList([]);
      getQuestionsData(prefix, selectedChallengeType);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  }

  const onShipToLLM = async () => {
    if (!llmList.length) return;
    setIsLoading(true);
    setLlmResponse(null);
    const messages = llmList.map(item => {
      const content = {
        id: item.id,
        prompt: item.prompt
      };
      if (item.answer_options) content.answer_options = item.answer_options;
      if (item.solution_index !== undefined && item.solution_index !== null) content.solution_index = item.solution_index;
      if (item.solution !== undefined && item.solution !== null) content.solution = item.solution;
      if (item.prompt_image_url) content.prompt_image_url = item.prompt_image_url;

      return {
        content: JSON.stringify(content),
        name: "user"
      };
    });

    const messageBatches = chunkArray(messages, llmBatchSize);
    let allResponses = [];
    let csvContent = "id,flagged_reason,source,external_id,prompt,answer_options,solution_index,solution,prompt_image_url\n";
    try {
      for (let i = 0; i < messageBatches.length; i++) {
        try {
          const batchProgress = `Processing ${(i + 1)*llmBatchSize < messages.length
            ? (i + 1)*llmBatchSize : messages.length} messages out of ${messages.length}...`;
          console.log(batchProgress);
          setLlmBatchProgress(batchProgress);
          console.dir(messageBatches[i]);
          const response = await request("questions/llm_report", {
            payload: {
              provider: "OpenAI",
              model: "gpt-4-turbo-preview",
              system_message: {
                content: llmPrompt,
                role: "system",
              },
              messages: messageBatches[i],
              response_format: "json",
              event_type: "llm_request",
              temperature: 1.0,
            }
          });
          if (response) {
            console.log(response);
            const parsedResponse = JSON.parse(stripJsonWrapper(response));
            parsedResponse.forEach(item => {
              const id = `"${item.id}"`;
              const reason = `"${item.flagged_reason.replace(/"/g, '""')}"`;
              const matchingChallenge = llmList.find(challenge => challenge.id === item.id);
              const source = `"${matchingChallenge.source}"`;
              const external_id = matchingChallenge.external_id
                ? `"${matchingChallenge.external_id}"`
                : "";
              const prompt = `"${matchingChallenge.prompt.replace(/"/g, '""')}"`;
              const answer_options = matchingChallenge.answer_options
                ? `"${JSON.stringify(matchingChallenge.answer_options).replace(/"/g, '""')}"`
                : "";
              const solution_index = matchingChallenge.solution_index === null
              || matchingChallenge.solution_index === undefined
                ? ""
                : `"${matchingChallenge.solution_index}"`;
              const solution = matchingChallenge.solution === null
              || matchingChallenge.solution === undefined
                ? ""
                : `"${matchingChallenge.solution}"`;
              const prompt_image_url = matchingChallenge.prompt_image_url
                ? `"${matchingChallenge.prompt_image_url}"`
                : "";
              const row = `${id},${reason},${source},${external_id},${prompt},${answer_options},${solution_index},${solution},${prompt_image_url}\n`;
              csvContent += row;
            });
            allResponses = allResponses.concat(parsedResponse);
            setLlmResponse(allResponses);
          }
          if (i < messageBatches.length - 1) {
            await new Promise(resolve => setTimeout(resolve, 100));
          }
        } catch (e) {
          setLlmResponse(`Batch ${i + 1} has failed, but the report will continue with the remaining ones...`);
          console.error(e);
        }
      }
      if (allResponses.length) {
        // Create a Blob from the CSV string and trigger the download
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "llm_responses.csv";
        link.click();
      } else {
        setLlmResponse("No challenges were flagged by LLM.");
      }
    } catch (e) {
      setLlmResponse("Error has occurred (see console for details).");
      console.error(e);
    } finally {
      setIsLoading(false);
      setLlmBatchProgress("CSV report should automatically download.");
      setDeleteList([]);
    }
  };

  const styles = {
    control: (css) => ({
      ...css,
      width: "calc(100vw - 312px - 44px)",
      maxWidth: "800px"
    }),
  };
  const placeholderPrefix = prefix ? `for standard ${prefix}` : "";

  const handleKeyPress = useCallback((event) => {
    if (event.ctrlKey && event.shiftKey && event.key === "D") {
      setDeleteList([...questionsData]);
    }
    if (event.ctrlKey && event.shiftKey && event.key === "A") {
      setLlmList([...questionsData]);
    }
    if (event.ctrlKey && event.shiftKey && event.key === "Z") {
      setDeleteList([]);
      setLlmList([]);
    }
  }, [questionsData]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => document.removeEventListener("keydown", handleKeyPress);
  }, [handleKeyPress]);

  if (loading) return <></>;
  if (authUserData.isAnonymous) history.push(generateRoutePath(constants.ADMIN_LOGIN_ROUTE_NAME));

  return (
    <div>
      {isLoading && <div style={{ left: "50%", position: "absolute", top: "50%"}}>
        <FontAwesomeIcon
          icon={faSpinner}
          spin
          size="5x"
        />
      </div>}
      {deleteList.length ? (<div style={{
        alignSelf: "flex-end",
        bottom: "15px",
        color: "red",
        right: "18px",
        cursor: "pointer",
        position: "fixed"}} onClick={onToggleDeleteModal}>
        <FontAwesomeIcon
          icon={faTrash}
          size="3x"
        />
      </div>) : null}
      {llmList.length ? (<div style={{
        alignSelf: "flex-end",
        bottom: "82px",
        color: "blue",
        right: "10px",
        cursor: "pointer",
        position: "fixed"}} onClick={onToggleLLMModal}>
        <FontAwesomeIcon
          icon={faRobot}
          size="3x"
        />
      </div>) : null}
      <div style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifySelf: "center",
        marginBottom: "35px",
        width: "calc(100vw - 312px - 44px)",
        maxWidth: "800px" }}>
        <p>Fetched {questionsData.length} challenge(s) {placeholderPrefix}</p>
        <br/>
        <Select
          isDisabled={isLoading}
          isSearchable placeholder="Select standard..."
          options={getOptions()}
          onChange={handleOnStandardsChange}
          styles={styles}
        />
        <br/>
        <div style={{ display: "flex", gap: "24px" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
            Challenge type:
            <Select
              isDisabled={isLoading}
              value={CONSENSUS_VARIANT_OPTIONS.find(option => option.value === selectedChallengeType)}
              isSearchable
              placeholder="Select challenge type..."
              options={CONSENSUS_VARIANT_OPTIONS}
              onChange={handleOnFormatChange}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
            Status:
            <Select
              isDisabled={isLoading}
              value={CHALLENGE_STATUS_OPTIONS.find(option => option.value === selectedChallengeStatus)}
              isSearchable
              placeholder="Filter by status..."
              options={CHALLENGE_STATUS_OPTIONS}
              onChange={handleOnStatusChange}
            />
          </div>
        </div>
      </div>
      {!isLoading && <div style={{ alignItems: "center", display: "grid", justifyItems: "center" }}>
        {questionsData.map((challenge, index) => (
          <div style={{ borderBottom: "1px solid black", display: "flex", marginBottom: "15px", gap: "24px" }}>
            <div style={{ position: "relative" }}>
              <ConsensusActivityRender
                key={index}
                hideQuestion={false}
                challenge={buildPreviewChallenge(challenge)}
                onMCAnswer={() => {}}
                onORAnswer={() => {}}
                isMathJaxSet={false} // Needed to prevent race condition on MathJax typesetting.
              />
              {deleteList.some(item => item.id === challenge.id) && (
                <img
                  src={svgCrossDataURL}
                  alt="Red Cross"
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    pointerEvents: "none",
                  }}
                />
              )}
            </div>
            <div style={{ alignSelf: "center", display: "flex", flexDirection: "column", fontSize: "10px", gap: "8px", textAlign: "center" }}>
              <span>ID: {challenge.id}</span>
              <span>Source: {challenge.source}</span>
              {challenge.external_id && <span>External ID: {challenge.external_id}</span>}
              <span>Status: {challenge.status.toUpperCase()}</span>
              <button style={{
                borderRadius: "100px",
                borderWidth: "1px",
                alignSelf: "center"
              }} onClick={() => onEdit(challenge)}>
                <FontAwesomeIcon icon={faEdit} />
                &nbsp;Edit
              </button>
              {challenge.id && <button style={{
                borderRadius: "100px",
                borderWidth: "1px",
                alignSelf: "center",
                width: "115px"
              }} onClick={() => {
                if (deleteList.some(item => item.id === challenge.id)) {
                  onRemoveFromDeleteList(challenge);
                } else {
                  onAddToDeleteList(challenge);
                }
              }}>
                {deleteList.some((item) => item.id === challenge.id)
                  ? (<><FontAwesomeIcon icon={faCancel} /> &nbsp;Undo Deletion</>)
                  : (<><FontAwesomeIcon icon={faTrash} /> &nbsp;Mark For Deletion</>)
                }
              </button>}
              {challenge.id && <button style={{
                borderRadius: "100px",
                borderWidth: "1px",
                alignSelf: "center",
                width: "115px"
              }} onClick={() => {
                if (llmList.some(item => item.id === challenge.id)) {
                  onRemoveFromLLMList(challenge);
                } else {
                  onAddToLLMList(challenge);
                }
              }}>
                {llmList.some((item) => item.id === challenge.id)
                  ? (<><FontAwesomeIcon icon={faCancel} /> &nbsp;Unmark For LLM</>)
                  : (<><FontAwesomeIcon icon={faRobot} /> &nbsp;Mark For LLM</>)
                }
              </button>}
            </div>
          </div>
        ))}
      </div>
      }
      {challengeToEdit && <EditConsensusQuestionModal challenge={challengeToEdit}
                                                      isModalOpen={isModalOpen}
                                                      modalTitle="Edit Challenge"
                                                      setIsModalOpen={setIsModalOpen}
                                                      setNeedsRefresh={setNeedsRefresh} />
      }
      <Modal show={isShowDeleteModal} size="lg" onHide={onToggleDeleteModal}>
        {isLoading && <div style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 9999
        }}>
          <FontAwesomeIcon
            icon={faSpinner}
            spin
            size="5x"
          />
        </div>}
        <Modal.Header><span style={{ fontSize: "20px" }}>{!deleteAPIResponse
          ? `Are you sure?`
          : `Deletion Results`}</span></Modal.Header>
        <Modal.Body>
          {deleteAPIResponse
            ? <pre>{deleteAPIResponse}</pre>
            : <span>
              You are about to delete {deleteList.length} challenge(s).<br/>This operation cannot be undone!!!
          </span>}
        </Modal.Body>
        <Modal.Footer>
          {!deleteAPIResponse && <Button
            onClick={onDeleteChallenges}
            style={{
              backgroundColor: colors.pink500,
              border: "none",
              borderRadius: "20px",
              fontSize: "16px",
              cursor: isLoading || !deleteList.length ? "not-allowed" : "pointer",
              opacity: isLoading || !deleteList.length ? 0.5 : 1
            }}
          >
            Delete
          </Button>}
          <Button onClick={onToggleDeleteModal}
                  disabled={isLoading}
                  style={{
                    backgroundColor: "white",
                    border: `1px solid ${colors.nightlyWoods500}`,
                    borderRadius: "20px",
                    cursor: isLoading ? "not-allowed" : "pointer",
                    color: colors.nightlyWoods500,
                    opacity: isLoading ? 0.5 : 1,
                    fontSize: "16px" }}
          >
            {deleteAPIResponse ? <>Close</> : <>Cancel</>}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={isShowLLMModal} onHide={onToggleLLMModal} size="lg">
        {isLoading && <div style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 9999
        }}>
          <FontAwesomeIcon
            icon={faSpinner}
            spin
            size="5x"
          />
        </div>}
        <Modal.Header><span style={{ fontSize: "20px" }}>LLM Challenge Quality Control Tool</span></Modal.Header>
        <Modal.Body>
          <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
            <div>
              <Label>LLM Prompt</Label>
              <Input placeholder="LLM Prompt"
                     type="textarea"
                     style={{ fontSize: "12px", height: "350px" }}
                     onChange={(e) => setLlmPrompt(e.target.value)}
                     value={llmPrompt} />
            </div>
            <div>
              <Label>Batch size</Label>
              <Input placeholder="Number of challenges in a batch, 50 is recommended"
                     type="number"
                     min={1}
                     max={100}
                     onChange={(e) => setLlmBatchSize(e.target.value)}
                     value={llmBatchSize} />
            </div>
            {llmBatchProgress ?? null}
            <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
            {llmResponse && Array.isArray(llmResponse) && llmResponse.length
              ? JSON.stringify(llmResponse, null, 2)
              : llmResponse}
          </pre>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={onShipToLLM}
            style={{
              backgroundColor: colors.pink500,
              border: "none",
              borderRadius: "20px",
              fontSize: "16px",
              cursor: isLoading || !llmList.length ? "not-allowed" : "pointer",
              opacity: isLoading || !llmList.length ? 0.5 : 1
            }}
          >
            Send to LLM
          </Button>
          <Button onClick={onToggleLLMModal}
                  style={{
                    backgroundColor: "white",
                    border: `1px solid ${colors.nightlyWoods500}`,
                    borderRadius: "20px",
                    color: colors.nightlyWoods500,
                    fontSize: "16px" }}
          >
            {llmResponse ? <>Close</> : <>Cancel</>}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
const chunkArray = (arr, size) => {
  return Array.from({ length: Math.ceil(arr.length / size) }, (_, i) =>
    arr.slice(i * size, i * size + size)
  );
};

const stripJsonWrapper = (response) => {
  const match = response.match(/^```json\s*([\s\S]*?)\s*```$/);
  return match ? match[1] : response;
}
