import { colors, divider, getSessionItem } from "utils";
import Accordion from "react-bootstrap/Accordion";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { request } from "hooks/useOkoApi/useOkoApi";
import * as Sentry from "@sentry/react";
import { LLMEventItem } from "./LLMEventItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export const LLMEvent = forwardRef(({ index, llm_request_ts, llm_request, llm_response_ts, llm_response }, ref) => {
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [messages, setMessages] = useState(llm_request.messages);
  const [newLLMResponse, setNewLLMResponse] = useState();
  const promptTextareaRef = useRef(llm_request.system_message.content);
  const originalResponseTextareaRef = useRef(llm_response.parsed_response);
  const llmEventItemRefs = useRef([]);

  useImperativeHandle(ref, () => ({
    adjustTextAreas: () => {
      requestAnimationFrame(() => {
        adjustPromptHeight();
        adjustOriginalResponseHeight();
      });
    }
  }));

  const adjustTextArea = () => {
    llmEventItemRefs.current.forEach(ref => {
      if (ref) {
        ref.adjustTextArea();
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    adjustPromptHeight();
  };

  const setLlmEventItem = (index, content, name, role) => {
    const newMessages = structuredClone(messages);
    newMessages[index] = { content: content, name: name, role: role };
    setMessages(newMessages);
  };

  const adjustPromptHeight = () => {
    if (promptTextareaRef.current) {
      const textarea = promptTextareaRef.current;
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const adjustOriginalResponseHeight = () => {
    if (originalResponseTextareaRef.current) {
      const textarea = originalResponseTextareaRef.current;
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleGenerate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    await request(`session_summarization/generate_llm_event`, {
      payload: {
        provider: "OpenAI",
        model: formData.llm,
        system_message: {
          content: formData.prompt,
          role: "system",
        },
        messages: messages,
        response_format: "LookForResponse",
        event_type: "llm_request",
        temperature: formData.temperature,
      }
    }).then((response) => {
      setNewLLMResponse(response);
      setIsLoading(false);
    }).catch((e) => {
      console.log(e);
      setIsLoading(false);
      Sentry.captureException(e);
      const errors = {};
      if (e.detail && Array.isArray(e.detail)) {
        e.detail.forEach((error) => {
          errors[error.loc.pop()] = error.msg;
        });
      } else {
        errors["generation"] = e?.detail || e?.message || "An unknown error occurred.";
      }
      setError(errors);
    });
  };

  const alertStyle = {
    padding: "8px",
    marginBottom: 0,
    marginTop: "16px"
  };

  useEffect(() => {
    setFormData(
      {
        prompt: llm_request.system_message?.content,
        llm: llm_request.model,
        temperature: llm_request.temperature ?? null,
      }
    );
  }, []);

  const calculateLatency = (llm_response_ts, llm_request_ts) => {
    if (!llm_response_ts || !llm_request_ts) return "N/A";
    return (new Date(llm_response_ts) - new Date(llm_request_ts) + " ms");
  };

  return (
    <Accordion.Item
      eventKey={index}
      key={index}
      style={{ backgroundColor: colors.neutral100, border: "none", borderRadius: "12px", padding: "16px 0" }}>
      <Accordion.Header>
        <div style={{ display: "flex", flexDirection: "row", gap: "24px", padding: "0 24px" }}>
          {getSessionItem("LLM Request Timestamp", llm_request_ts)}
          {divider}
          {getSessionItem("LLM Response Timestamp", llm_response_ts)}
          {divider}
          {getSessionItem("Latency", calculateLatency(llm_response_ts, llm_request_ts))}
          {divider}
          {getSessionItem("Detected", JSON.parse(llm_response.parsed_response)?.look_for_type) ?? "N/A"}
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <div style={{
          alignItems: "center",
          backgroundColor: "white",
          borderRadius: "12px",
          boxShadow: "0px 1px 2px 0px rgba(14, 26, 49, 0.05)",
          padding: "32px" }}>
          <Form onSubmit={handleGenerate}>
            <div style={{ display: "flex", flexDirection: "column" , gap: "32px" }}>
              <div style={{ display: "flex", flexDirection: "column" , gap: "24px" }}>
                <Form.Group>
                  <Form.Label style={{ fontWeight: "bold" }}>Prompt</Form.Label>
                  <Form.Control
                    ref={promptTextareaRef}
                    required
                    as="textarea"
                    placeholder="Enter new prompt"
                    value={formData.prompt}
                    name="prompt"
                    onChange={handleChange}
                    style={{ overflow: "hidden" }}
                  />
                  {error?.prompt && (
                    <Alert style={alertStyle} variant="danger">{error.prompt}</Alert>
                  )}
                </Form.Group>
                <div style={{ display: "flex", gap: "32px" }}>
                  <Form.Group style={{ width: "100%" }}>
                    <Form.Label style={{ fontWeight: "bold" }}>LLM Name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter LLM name"
                      value={formData.llm}
                      name="llm"
                      onChange={handleChange}
                    />
                    {error?.llm && (
                      <Alert style={alertStyle} variant="danger">{error.llm}</Alert>
                    )}
                  </Form.Group>
                  <Form.Group style={{ width: "100%" }}>
                    <Form.Label style={{ fontWeight: "bold" }}>LLM Warmth</Form.Label>
                    <Form.Control
                      type="number"
                      min="0"
                      max="1.5"
                      step="0.01"
                      placeholder="Optional value between 0 and 1.5"
                      value={formData.temperature}
                      name="temperature"
                      onChange={handleChange}
                    />
                    {error?.temperature && (
                      <Alert style={alertStyle} variant="danger">{error.temperature}</Alert>
                    )}
                  </Form.Group>
                </div>
                {error?.generation && (
                  <Alert style={alertStyle} variant="danger">{error.generation}</Alert>
                )}
              </div>
              <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                {messages && messages.map((item, i) => (
                  <LLMEventItem
                    adjustTextArea={adjustTextArea}
                    content={item.content}
                    name={item.name}
                    role={item.role}
                    key={i}
                    index={i}
                    setLlmEventItem={setLlmEventItem}
                    ref={(el) => llmEventItemRefs.current[i] = el} />
                ))}
              </div>
              <Form.Group>
                <Form.Label style={{ fontWeight: "bold" }}>Original LLM Response</Form.Label>
                <Form.Control
                  ref={originalResponseTextareaRef}
                  required
                  as="textarea"
                  disabled
                  value={JSON.stringify(JSON.parse(llm_response.parsed_response), null, 2)}
                  name="prompt"
                  style={{ resize: "none", overflow: "hidden" }}
                />
              </Form.Group>
              <Button style={{
                backgroundColor: colors.nightlyWoods500,
                border: "none",
                borderRadius: "6px",
                fontSize: "16px",
                height: "40px",
                padding: "0 16px" }}
                      disabled={isLoading}
                      type="submit">
                Generate new LLM response
              </Button>
            </div>
          </Form>
          {isLoading && <div style={{ display: "flex", flexDirection: "column", marginTop: "32px" }}>
            <FontAwesomeIcon
              icon={faSpinner}
              spin
              size="5x"
            />
          </div>}
          {newLLMResponse && <div style={{
            alignItems: "center",
            backgroundColor: "white",
            borderRadius: "12px",
            boxShadow: "0px 1px 2px 0px rgba(14, 26, 49, 0.05)",
            marginTop: "32px",
            padding: "32px",
            whiteSpace: "pre-wrap" }}>
            {JSON.stringify(newLLMResponse, null, 2)}
          </div>}
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
});
