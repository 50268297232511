import { useState } from "react";
import { useDataTrack } from "hooks/livekit/useDataTrack/useDataTrack";
import { useSessionActivity } from "hooks/useSessionActivity/useSessionActivity";
import { useOkoApi } from "hooks/useOkoApi/useOkoApi";
import { useSessionContext } from "hooks/useSession";
import { useLocalParticipantUserId } from "hooks/useLocalParticipant/useLocalParticipant.js";
import { constants } from "utils";

export const useConsensusOkoSession = () => {
  const { fetch } = useOkoApi();
  const { session } = useSessionContext();
  const { change: changeActivity } = useSessionActivity();
  const [activityStarted, setActivityStarted] = useState(false);
  const userId = useLocalParticipantUserId();

  useDataTrack((e) => {
    if (session?.sessionActivityType !== constants.CONSENSUS_ENUM) return;
    if (e?.event_type === "gesture") return;
    if (e?.event_type === "readiness") return;
    if (!activityStarted && e?.event_type.includes("introduction")) {
      setActivityStarted(true);
      changeActivity({ id: "multiple-choice" });
    }
  });

  const multipleChoiceQuestionResponse = (activity, index) => {
    const payload = {
      challenge_id: activity.challenge_id,
      oko_user_id: userId,
      option_index: index,
      linked_event_id: activity.event_id,
    };

    return fetch(`session/${session.okoSessionId}/challenge/response`, {
      payload,
    });
  };


  const openResponseQuestionResponse = (activity, solution) => {
    const payload = {
      challenge_id: activity.challenge_id,
      oko_user_id: userId,
      solution: solution,
      linked_event_id: activity.event_id,
    };

    return fetch(`session/${session.okoSessionId}/challenge/response`, {
      payload,
    });
  };

  return { multipleChoiceQuestionResponse, openResponseQuestionResponse };
};
