import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "@bootstrap-styled/v4";
import { useState } from "react";
import { ConsensusQuestion } from "./ConsensusQuestion";
import { PreviewConsensusQuestionModal } from "./PreviewConsensusQuestionModal";

export const EditConsensusQuestionModal = ({
                                                  challenge,
                                                  isModalOpen,
                                                  modalTitle,
                                                  setIsModalOpen,
                                                  setNeedsRefresh }) => {
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Modal style={{ maxWidth: "1000px" }} size="lg" isOpen={isModalOpen} toggle={handleClose}>
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalBody>
          <ConsensusQuestion challenge={challenge} showHeader={false} setNeedsRefresh={setNeedsRefresh}/>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <PreviewConsensusQuestionModal challenge={challenge}
                                     isModalOpen={isPreviewModalOpen}
                                     setIsModalOpen={setIsPreviewModalOpen}
                                     setNeedsRefresh={setNeedsRefresh} />
    </div>
  );
};
