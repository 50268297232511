import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { Track } from "livekit-client";
import { Alert, Button, Card } from "react-bootstrap";
import { Video } from "../shared/Video/Video";
import { ActionBar } from "../shared/ActionBar/ActionBar";
import useVideoContext from "hooks/livekit/useVideoContext/useVideoContext";
import { useAuthUserDataContext } from "hooks/useAuthUserData/useAuthUserData";
import { useSessionContext } from "hooks/useSession";
import { useFirebase } from "hooks/useFirebase";
import { colors } from "utils";
import { SessionTrigger } from "../SessionTrigger";
import { OKOFooter } from "../../../OKOFooter";
import logo from "../../../../assets/OKO_Core_Logotype2.svg";

export const Join = ({ error, isLoading, setIsLoading, setError, isSpectator, isWaitingForSessionToStart }) => {
  const { authUserData } = useAuthUserDataContext();
  const { session } = useSessionContext();
  const [sessionReady, setSessionReady] = useState(false);
  const { localTracks } = useVideoContext();
  const videoTrack = localTracks.find((track) => track?.source?.includes(Track.Source.Camera));
  const firebase = useFirebase();

  useEffect(() => {
    if (!session) return;
    if (sessionReady) return;
    if (session?.creatorId !== authUserData?.id) return;
    setSessionReady(true);
  }, [setSessionReady, sessionReady, authUserData, session]);

  const onLogOut = async () => {
    await firebase.auth().signOut();
    window.location.href = "https://okolabs.ai/";
  };

  return (
    <div className="d-flex fullscreen flex-column justify-content-center">
      <div className="login-decoration login-decoration-portrait">
      </div>
      <Card className="login-card login-card-small-width" style={{ height: "fit-content", padding: "32px" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "32px" }}>
          <img src={logo} alt="logo" style={{ alignSelf: "center", width: "196px" }} />
          {!isSpectator && <VideoWrapper>
            <Video track={videoTrack} isLocal />
            <VideoControlBarWrapper>
              <ActionBar />
            </VideoControlBarWrapper>
          </VideoWrapper>}
          {isLoading && isWaitingForSessionToStart && <p style={{ textAlign: "center" }}>
            Please wait for the session to start!</p>}
          <SessionTrigger isLoading={isLoading}
                          setIsLoading={setIsLoading}
                          setError={setError}
                          isSpectator={isSpectator} />
          {error &&
            <Alert style={{ overflowWrap: "break-word" }}
                   dismissible
                   onClose={() => setError(null)}
                   variant="danger">
              {error}
            </Alert>}
        </div>
      </Card>
      <Button style={{
        backgroundColor: "white",
        border: "1px solid #ABB1BA",
        color: colors.nightlyWoods500,
        position: "absolute",
        top: "8px",
        right: "8px",
        width: "150px !important" }}
              onClick={onLogOut}>
        Log Out <FontAwesomeIcon icon={faSignOutAlt} className="ml-2" />
      </Button>
      <OKOFooter />
    </div>
  );
};

const VideoWrapper = styled(Card)`
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  width: 100%;
`;

export const VideoControlBarWrapper = styled.div`
  align-content: center;
  position: absolute;
  right: 0;
  top: 0;
  padding: 8px;
  height: 100%;
`;
