import { useState } from "react";
import { useDataTrack } from "hooks/livekit/useDataTrack/useDataTrack";
import hushIcon from "assets/hush.svg";
import talkIcon from "assets/talk.svg";
import nextIcon from "assets/next.svg";
import starIcon from "assets/star.svg";

export const ConsensusIndicators = ({ isSolutionEvent, isChallengeSolved }) => {
  const [isShowHushIcon, setIsShowHushIcon] = useState(false);
  const [isShowTalkIcon, setIsShowTalkIcon] = useState(false);
  const [isShowSolutionIcon, setIsShowSolutionIcon] = useState(true);

  useDataTrack((e) => {
    if (e.event_type === "state_change"){
      if (e.data?.state === "gather" && e.data?.previous_state === "challenge") {
        setIsShowHushIcon(true);
        setIsShowTalkIcon(false);
      } else if (e.data?.state === "gather" || e.data?.state === "discuss") {
        setIsShowHushIcon(false);
        setIsShowTalkIcon(true);
      } else {
        setIsShowHushIcon(false);
        setIsShowTalkIcon(false);
      }
      if (e.data?.state === "complete") setIsShowSolutionIcon(false);
    }
  });

  const getIcon = (iconName) => {
    return <span style={{ alignContent: "center" }}>
      <img src={iconName}/>
    </span>;
  };

  const getTextElement = (text) => {
    return <span style={{ alignContent: "center", fontSize: "20px", fontWeight: 500 }}>{text}</span>
  }

  let icon = <></>;
  let text = <></>;

  if (isShowHushIcon) {
    icon = getIcon(hushIcon);
    text = getTextElement("Solo Time");
  } else if (isShowTalkIcon) {
    icon = getIcon(talkIcon);
    text = getTextElement("Team Time");
  }
  if (isSolutionEvent && isChallengeSolved) {
    icon = getIcon(starIcon);
    text = getTextElement("Completed!")
  } else if (isSolutionEvent && !isChallengeSolved && isShowSolutionIcon) {
    icon = getIcon(nextIcon);
    text = getTextElement("Move On")
  }

  return(
    <div style={{ display: "flex", flexDirection: "column", gap: "24px", width: "350px" }}>
      <ConsensusIndicator icon={icon} text={text} />
    </div>
  );
};

const ConsensusIndicator = ({ icon, text }) => {
  return(
    <div style={{ display: "flex", gap: "16px" }}>
      {icon}
      {text}
    </div>
  );
};