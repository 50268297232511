import "./groupCode.css";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Form, Button } from "react-bootstrap";
import { useSessionContext } from "hooks/useSession";
import { colors } from "utils";

export const SessionTrigger = ({ isLoading, isError, setError, isSpectator }) => {
  const { setIsStart, setIsStartObserving } = useSessionContext();
  const [isShowStartButton, setIsShowStartButton] = useState(false);

  useEffect(() => {
    // Don't display start button right away to give some time for the video track to display.
    // This way users would more intuitively interact with media devices settings if they need to select another device.
    // Also, starting a session too fast while the local tracks are being mounted in the first place
    // could result in a potential race condition inside LiveKit's publishTrack method.
    setTimeout(() => {
      setIsShowStartButton(true)
    }, 2000)
  })

  const onSubmit = (e) => {
    if (!isError) {
      e.preventDefault();
      if (isSpectator) {
        setError(null)
        setIsStartObserving(true);
      } else {
        setError(null)
        setIsStart(true);
      }
    }
  };

  const buttonStyle = {
    backgroundColor: colors.pink500,
    border: "none",
    borderRadius: "20px",
    fontSize: "16px",
    height: "48px" };
  const buttonText = isShowStartButton ? (isSpectator ? "Start observing" : "Join") : "";

  return (
    isLoading ? (
      <div style={{ alignSelf: "center" }}>
        <FontAwesomeIcon
          icon={faSpinner}
          spin
          size="5x"
        />
      </div>
    ) : (
      <div style={{ display: "flex", flexDirection: "column", gap: "32px" }}>
        <Form className="drop-in" onSubmit={onSubmit}>
          <Button
            disabled={!isShowStartButton}
            type="submit"
            size="lg"
            className="w-100"
            style={buttonStyle}>{buttonText}
            {isShowStartButton ?
              <FontAwesomeIcon icon={faSignInAlt} className="ml-2" />
              :
              <FontAwesomeIcon icon={faSpinner} spin />
            }
          </Button>
        </Form>
      </div>
    )
  );
};
