import { mediaBreakpointDown } from "@bootstrap-styled/css-mixins/lib/breakpoints";
import { createGlobalStyle } from "styled-components";
import background from "assets/abstract-shape-bg.svg";
import { colors } from "utils";

export const GlobalStyle = createGlobalStyle`
    html, body, #root, #root > div, .fullscreen {
      min-height: 100vh;
      min-width: 100vw;
      overflow-x: hidden;
      caret-color: transparent;
      color: ${colors.nightlyWoods950};
      font-family: "Roboto", system-ui;
    }

    input, textarea {
      caret-color: black;
    }

    @supports (-webkit-touch-callout: none) {
        body, #root, #root > div, .fullscreen {
            /* The hack for Safari */
            height: -webkit-fill-available;
        }
    }

    .btn{
        font-family: ${(props) => props.theme["$btn-font-family"]};
    }

    .slash-background{
        background-color: white;
        background-image: url(${background});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    ${(props) => `
        ${mediaBreakpointDown(
          "md",
          props.theme["$grid-breakpoints"],
          `   
            h1{
                font-size: 2.2em !important;
            }

          `
        )}
    `}

`;
