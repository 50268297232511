import styled from "styled-components";

import { Activity } from "./Activity/Activity";
import { VideosBar } from "components/pages/Session/shared/VideosBar/VideosBar";
import { CalculatorActivityProvider } from "hooks/activities/calculator/useCalculatorActivity/useCalculatorActivity";

import { VideoUserStatus } from "./VideoUserStatus/VideoUserStatus";
import { useSessionContext } from "hooks/useSession";
import { OkoAssistant } from "../../../OkoLesson/OkoAssistant/OkoAssistant";

export const CalculatorActivity = () => {
  const { userIsSessionAdmin } = useSessionContext();

  return (
    <CalculatorActivityProvider>
      <Container>
        <div style={{
          display: "flex",
          position: "fixed",
          height: "100vh",
          left: 0,
          top: 0,
          width: "405px" }}>
          <OkoAssistant />
        </div>
        <Activity />
        <VideosBar videoWidget={userIsSessionAdmin && VideoUserStatus} />
      </Container>
    </CalculatorActivityProvider>
  );
};

const Container = styled.div`
  justify-content: center;
  position: fixed;
  display: grid;
  height: 100vh;
  margin-left: 405px;
  margin-right: 20px;
  overflow: auto;
`;
