import { useCallback, useState } from "react";

export const useSessionInspectorToggle = () => {
  const [isShowSessionInspector, setIsShowSessionInspector] = useState(false);
  const toggleSessionInspector = useCallback(
    () => setIsShowSessionInspector((prev) => !prev), []
  );

  return { isShowSessionInspector, toggleSessionInspector };
};
