import "./consensusActivity.css"
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Card, ListGroup } from "@bootstrap-styled/v4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { VerticalFraction } from "./VerticalFraction";
import { MathJax } from "./MathJax";
import { useSessionContext } from "hooks/useSession";
import { Celebration } from "../../../Celebration/Celebration";
import { Button } from "react-bootstrap";
import {
  addLeadingZeroIfDot,
  colors,
  isOpenResponseSolutionEmpty,
  removeTrailingZeros,
  validOpenResponseSolution
} from "utils";

const OpenResponseWidget = ({onORAnswer, init_solution, disabled, isDataStateReset, units, render_type, isMathJaxSet}) => {
  const [solution, setSolution] = useState((init_solution === null || init_solution === undefined)
    ? ""
    : init_solution);
  const [isBlinking, setIsBlinking] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const handleClick = () => {
    if (!isChanged && !isDataStateReset) return;
    const sumbittedSolution = removeTrailingZeros(solution);
    setSolution(sumbittedSolution);
    setIsSubmitted(true);
    setIsBlinking(true);
    setTimeout(() => setIsBlinking(false), 250);
    onORAnswer(sumbittedSolution);
    setIsChanged(false);
  };

  const clickStyle = `
    @keyframes blink-animation {
      50% {
      transform: scale(1.2);
      }
    }
  `;

  const buttonStyle = {
    backgroundColor: "white",
    border: `1 px solid ${colors.blue500}`,
    borderRadius: "20px",
    color: colors.nightlyWoods500,
    fontWeight: 500,
    animation: isBlinking ? "blink-animation 0.25s ease-in-out" : "none",
    padding: "12px 24px"
  };

  const disabledButtonStyle = {
    backgroundColor: colors.nightlyWoods100,
    cursor: "not-allowed",
    pointerEvents: "auto"
  };

  const submittedButtonStyle = {
    backgroundColor: colors.blue500,
    color: colors.milkGlass500,
    cursor: "not-allowed"
  };

  const resubmittedButtonStyle = {
    backgroundColor: colors.blue500,
    color: colors.milkGlass500,
    opacity: 0.75
  };

  const inputStyle = {
    width: "150px",
    borderRadius: "6px",
    border: `1px solid ${colors.milkGlass950}`,
    backgroundColor: "white",
    outline: "none",
    textAlign: "center"
  };

  const disabledInputStyle = {
    cursor: "not-allowed",
    border: "1px solid rgba(0, 0, 0, 0.125)",
    background: colors.nightlyWoods100
  };

  const isButtonDisabled = () => {
    // Disable button if solution is invalid (e.g. "" or "-")
    return (disabled || isOpenResponseSolutionEmpty(solution))
  };

  let buttonText = "Submit";

  const appliedButtonStyle = () => {
    if (isButtonDisabled()) return {...buttonStyle, ...disabledButtonStyle};
    if (isChanged && isSubmitted && !isDataStateReset) {
      buttonText = "Resubmit";
      return {...buttonStyle, ...resubmittedButtonStyle};
    }
    if (isSubmitted && !isDataStateReset) {
      buttonText = "Submitted";
      return {...buttonStyle, ...submittedButtonStyle};
    }
    return buttonStyle;
  }

  return <div style={{ justifyContent: "center", display: "flex", gap: "32px", marginTop: "1.5rem" }}>
    <input
      className="answer"
      type="text"
      disabled={disabled}
      value={solution}
      onChange = {(e) => {
        const value = e.target.value;
        if (validOpenResponseSolution(value)) {
          // We want the user to be able to enter a minus sign, or a trailing dot,
          // but technically it is not a valid solution (yet)
          setSolution(addLeadingZeroIfDot(value));
          setIsChanged(true);
        }
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter" && !isButtonDisabled()) {
          handleClick();
        }
      }}
      style={ disabled ? {...inputStyle, ...disabledInputStyle} : inputStyle }
    />
    <style>{clickStyle}</style>
    <div className="answer" style={{ alignSelf: "center" }}>{renderText(units, render_type, isMathJaxSet)}</div>
    <Button
      disabled={isButtonDisabled()}
      onClick={handleClick}
      style={appliedButtonStyle()}
      type="submit">
      {buttonText}
    </Button>
  </div>
};

const removeUnits = (prompt) => {
  // Find the last occurrence of '.', '?', or '!'
  const regex = /([?!\.])[^?!\.]*$/;
  const lastPunctuationMatch = prompt.match(regex);
  if (lastPunctuationMatch) {
    const lastPunctuationIndex = prompt.lastIndexOf(lastPunctuationMatch[1]); // Get the last punctuation position
    let extractedUnits = prompt.slice(lastPunctuationIndex + 1).trim(); // Extract words after punctuation
    let modifiedPrompt = prompt.slice(0, lastPunctuationIndex + 1); // Keep the sentence without extracted text

    // Special case: Extract unit like "ft." if it's the last word after an equation
    const equationMatch = prompt.match(/=\s*([\w$%\.]+)/);
    if (equationMatch && !/^\d/.test(equationMatch[1]) && !/^[A-Z]/.test(equationMatch[1])) {
      extractedUnits = equationMatch[1];
      modifiedPrompt = prompt.replace(new RegExp(`\\s*${equationMatch[1]}$`), ""); // Remove extracted unit
    }

    // If the extracted part starts with a digit or a capital letter, it is ignored
    if (!/^\d/.test(extractedUnits) && !/^[A-Z]/.test(extractedUnits)) {
      return { prompt: modifiedPrompt, units: extractedUnits };
    }
  }
  return { prompt: prompt };
};

const renderText = (text, render_type, isMathJaxSet) => {
  if (text === null || text === undefined) return <></>;
  if (render_type === 'math') {
    // Can not use handleMultiLine function here as it could match MathJax's \ne in addition to \n
    return <MathJax expression={text} isMathJaxSet={isMathJaxSet}/>;
  } else {
    return <>{handleMultiLine(text)}</>;
  }
}

export const ConsensusActivityRender = ({
                                             hideQuestion,
                                             selectedIndex,
                                             challenge,
                                             disableOptions,
                                             onMCAnswer,
                                             onORAnswer,
                                             isMathJaxSet,
                                             isChallengeSolved }) => {

  const { isCelebrating, setIsCelebrating } = useSessionContext();

  const renderPromptImage = (url) => {
    if (!url || url === 'nan') {
      return null;
    } else {
      return <img src={url} style={{
        border: `1px solid ${colors.nightlyWoods500}`,
        display: "flex",
        justifySelf: "center",
        maxHeight: "225px",
        maxWidth: "-webkit-fill-available" }} alt="Prompt image"/>
    }
  };

  const processedPrompt = (challenge?.solution === null || challenge?.solution === undefined)
    ? challenge.prompt
    : removeUnits(challenge.prompt).prompt;

  useEffect(() => {
    if (isChallengeSolved) {
      setIsCelebrating(true);
    }
    return () => {
      setIsCelebrating(false);
    }
  }, [isChallengeSolved]);

  return (
    <>
      {isCelebrating && <Celebration />}
      {!hideQuestion && (
        <div style={{ justifyItems: "center", maxWidth: "761px", padding: "24px" }}>
          <div className="prompt">
            {renderText(processedPrompt, challenge?.render_type)}
          </div>
          {renderPromptImage(challenge.internal_image_url ?? challenge.prompt_image_url)}
          {challenge?.answer_options ?
            <ListGroup style={{
              border: "none",
              gap: "16px",
              minWidth: "240px",
              justifySelf: "center",
              translate: "-21px",
              marginTop: "34px" }}>
            {challenge.answer_options.map((option, index) => (
              <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
                <FontAwesomeIcon
                  color={"#00A35A"}
                  size="3x"
                  icon={faCheckCircle}
                  style={{ alignSelf: "center", opacity: challenge.solution_index === index ? 1 : 0 }}
                />
                <Option
                  className={
                    `w-100 d-flex
                    ${!option.eligible || disableOptions ? "disabled" : ""}
                    ${selectedIndex === index ? "selected" : ""}
                    ${challenge.solution_index === index ? "correct" : ""}`
                  }
                  key={index}
                  style={{ padding: "1rem", opacity: option.eligible ? 1 : 0.3 }}
                  onClick={() => {
                    onMCAnswer(option, index);
                  }}
                >
                  <div>
                    <span key={index + option.id + "_number"} style={{ fontSize: 14, marginRight: "0.5rem" }}>
                      {numberToLetters(index)} )
                    </span>
                    <span className="answer" key={index + option.id + "_option"}>
                      {challenge.render_type === 'math' ?
                        (
                          <MathJax expression={option.text} isMathJaxSet={isMathJaxSet} />
                        ) : (
                          <>
                            {handleVerticalFractions(option.text)}
                          </>
                        )}
                    </span>
                  </div>
                </Option>
              </div>
            ))}
          </ListGroup> : (
            <OpenResponseWidget
              init_solution={challenge?.solution}
              onORAnswer={onORAnswer}
              disabled={disableOptions}
              isDataStateReset={selectedIndex === null}
              units={removeUnits(challenge?.prompt).units}
              render_type={challenge?.render_type}
              isMathJaxSet={isMathJaxSet}
            />
          )}
        </div>
      )}
    </>
  );
};

const Option = styled(Card)`
  border: 1px solid ${colors.blue500} !important;
  border-radius: 100px !important;
  color: #65558F;
  cursor: pointer;
  font-size: 1.3rem;
  flex-direction: row !important;
  align-items: center !important;
  background: white !important;
  padding: 8px 24px !important;

  &:hover {
    background: ${colors.blue200} !important;
  }
  
  &.disabled {
    cursor: not-allowed;
    background: ${colors.nightlyWoods100} !important;
  }
  
  &.selected {
    background: ${colors.blue500} !important;
    border: 1px solid ${colors.blue500} !important;
    color: ${colors.milkGlass500} !important;
  }

  &.correct {
    background: #00A35A !important;
    border: 1px solid #00A35A !important;
    color: white !important;
  }
`;

//convert number to abc etc
const numberToLetters = (number) => {
  var abc = "abcdefghijklmnopqrstuvwxyz";
  var abcLength = abc.length;
  var converted = "";

  while (number >= 0) {
    converted = abc.charAt(number % abcLength) + converted;
    number = Math.floor(number / abcLength) - 1;
  }

  return converted;
};

const handleMultiLine = (text) => {
  const newLine = '\\n'
  if(!text.includes(newLine)) return handleVerticalFractions(text);

  return text.split(newLine).map(
    (line, index) => (
      <React.Fragment key={index}>
        <div>{handleVerticalFractions(line)}</div>
        <br />
      </React.Fragment>
    ))
};

const handleVerticalFractions = (text) => {
  if (!text) return text;
  text = text.replace(/\u00a0/g, " "); // this replaces all NBSP characters with space
  if (text.indexOf("/") < 1) return text; // also handling a case where "/" is at index 0 - not a valid fraction
  const words = text.split(" ");
  let insertSpaceAfterFraction = false;
  for (let i = 0; i < words.length; i++) {
    if (words[i].indexOf("/") > 0) { // word with "/" at index 0 is not a valid fraction
      let top = words[i].split("/")[0];
      let charsBeforeFraction = "";
      // Handling a case where there is non-number char in the top, like opening parenthesis
      for (let j = 0; j < top.length; j++) {
        if (!isNumber(top.charAt(j))) {
          charsBeforeFraction = charsBeforeFraction + top.charAt(j);
        } else {
          break;
        }
      }
      top = top.slice(charsBeforeFraction.length);
      let bottom = words[i].split("/")[1];
      let charsAfterFraction = "";
      // Handling a case where there is no space after the fraction and non-number char goes next like "1/8?"
      for (let j = 0; j < bottom.length; j++) {
        if (!isNumber(bottom.charAt(j))) {
          charsAfterFraction = bottom.substring(j, bottom.length);
          bottom = bottom.substring(0, j);
          break;
        }
      }
      words[i] = <span>
        {charsBeforeFraction && <span>{charsBeforeFraction}</span>}
        <VerticalFraction key={i} numerator={top} denominator={bottom}/>
        {charsAfterFraction && <span>{charsAfterFraction}</span>}
      </span>;
      insertSpaceAfterFraction = true;
    } else {
      if (insertSpaceAfterFraction) {
        words[i] = <span key={i}>{" " + words[i] + " "}</span>;
      } else {
        words[i] = <span key={i}>{words[i] + " "}</span>;
      }
      insertSpaceAfterFraction = false;
    }
  }
  return words;
};

const isNumber = (str) => {
  if (typeof str != "string") return false
  return !isNaN(str) && !isNaN(parseFloat(str));
};
