import "./consensusActivity.css"
import React from 'react';
import { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { Fullscreen } from "components/shared/Fullscreen/Fullscreen";
import { request } from "hooks/useOkoApi/useOkoApi";
import { useActivity } from "hooks/useActivity/useActivity";
import { useOkoSessionContext } from "hooks/useOkoSession/useOkoSession";
import { useDataTrack } from "hooks/livekit/useDataTrack/useDataTrack";
import { useLocalParticipantUserId } from "hooks/useLocalParticipant/useLocalParticipant.js";
import { VideosBar } from "components/pages/Session/shared/VideosBar/VideosBar";
import useLocalVideoToggle from "hooks/livekit/useLocalVideoToggle/useLocalVideoToggle";
import { ConsensusActivityRender } from "./ConsensusActivityRender";
import { ConsensusLeftPanel } from "./ConsensusLeftPanel";
import { Timer } from "./CountdownTimer";
import IconPlusCircle from "assets/Icon-PlusCircle.svg";
import { colors } from "utils";

export const ConsensusActivity = () => {
  const {
    consensusSession: { multipleChoiceQuestionResponse, openResponseQuestionResponse },
  } = useOkoSessionContext();
  const { activity, session, update: updateActivity } = useActivity();
  const [timerValue, setTimerValue] = useState(null);
  const [timerTimeRemaining, setTimerTimeRemaining] = useState(null);
  const localParticipantUserId = useLocalParticipantUserId();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [disableOptions, setDisableOptions] = useState(false);
  const [hideQuestion, setHideQuestion] = useState(true);
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const [isVideoToggled, setIsVideoToggled] = useState(false);
  const [isSolutionEvent, setIsSolutionEvent] = useState(false);
  const [isChallengeSolved, setIsChallengeSolved] = useState(false);
  const [challenge, setChallenge] = useState(null);
  const [isShowRequestMoreTimeButton, setIsShowRequestMoreTimeButton] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [isMoreTimeRequested, setIsMoreTimeRequested] = useState(false);
  const [isFirstGather, setIsFirstGather] = useState(false);

  const dispatchMoreTimeRequest = async () => {
    await request(`session/${session.id}/challenge/ask_for_more_time`, {
    }).then(() => setIsMoreTimeRequested(true)
    ).catch((e) => {
      console.log(e);
      Sentry.captureException(e);
    });
  };


  useDataTrack((e) => {
    if (e.event_type === "state_change" && e.data?.state === "gather") {
      if (e.data.previous_state === "challenge") {
        setIsFirstGather(true);
      } else {
        setIsFirstGather(false);
      }
    }
    if (e.event_type !== "time_warning") return;
    if (!e?.data?.oko_user_ids?.includes(localParticipantUserId)) return;
    setTimerValue(e.data.time_remaining_seconds);
    if (e?.data?.is_more_time_request) {
      if (timeoutId) clearTimeout(timeoutId);
      setTimerTimeRemaining(null);
      setIsShowRequestMoreTimeButton(false);
      return;
    }
    // The timer widget appears only after the speech expression ends.
    // In order to reduce complexity and save on introducing another state variable,
    // the "more time" button will be displayed after 15 seconds after the event is received,
    // which is very close to 10 seconds after the speech expression ends.
    const id = setTimeout(() => setIsShowRequestMoreTimeButton(true), 15000);
    setTimeoutId(id);
  });

  useDataTrack((e) => {
    if (timerValue && e.event_type === "speech_end" && selectedIndex === null) {
      setTimerTimeRemaining(timerValue);
    }
  });

  useDataTrack((e) => {
    if (e.event_type !== "state_change") return;

    const data = { ...e?.data?.context, event_id: e.event_id };

    if (data?.challenge){
      /* We parse the challenge from the data directly because the activity.challenge 
      fields are merged, which causes different MC and Open response challenge fields
      from consecutive questions to be merged and displayed into one challenge, which 
      is problematic for rendering. */
      setChallenge(data?.challenge);
    }

    updateActivity(data, "multiple-choice");

    if (e?.data?.state === "challenge") {
      setHideQuestion(true); // Unmounting is required for correct MathJax rendering between challenges.
      setHideQuestion(false);
      setSelectedIndex(null);
      setTimerTimeRemaining(null);
      setTimerValue(null);
      setIsShowRequestMoreTimeButton(false);
      setTimeoutId(null);
      setDisableOptions(true);
      setIsSolutionEvent(false);
      setIsChallengeSolved(false);
    }

    if (e?.data?.state === "gather") {
      setHideQuestion(false);
      setTimerTimeRemaining(null);
      setTimerValue(null);
      setIsShowRequestMoreTimeButton(false);
      setTimeoutId(null);
      setDisableOptions(false);
      setIsSolutionEvent(false);
      setIsChallengeSolved(false);
    }

    if (e?.data?.state === "discuss") {
      setHideQuestion(false);
      setTimerTimeRemaining(null);
      setTimerValue(null);
      setIsShowRequestMoreTimeButton(false);
      setTimeoutId(null);
      setSelectedIndex(null);
      setDisableOptions(false);
      setIsSolutionEvent(false);
      setIsChallengeSolved(false);
    }

    if (e?.data?.state === "solution") {
      setSelectedIndex(null);
      setDisableOptions(true);
      setTimerTimeRemaining(null);
      setTimerValue(null);
      setIsShowRequestMoreTimeButton(false);
      setTimeoutId(null);
      setHideQuestion(false);
      setIsSolutionEvent(true);
      setIsChallengeSolved(e.data?.context?.challenge_solved);
    }

    if (e?.data?.state === "complete" && e?.data.previous_state === "solution") {
      setIsChallengeSolved(false);
    }
  });

  const onORAnswer = async (solution) => {
    openResponseQuestionResponse(activity, solution);
    setSelectedIndex("flagging OR answer submission");
    setIsShowRequestMoreTimeButton(false);
    setTimerTimeRemaining(null);
  }

  const onMCAnswer = async (option, index) => {
    if (disableOptions) return;
    if (!option.eligible) return;
    multipleChoiceQuestionResponse(activity, index);
    setSelectedIndex(index);
    setIsShowRequestMoreTimeButton(false);
    setTimerTimeRemaining(null);
  };

  useEffect(() => {
    if (!isVideoToggled && isVideoEnabled) {
      toggleVideoEnabled();
      setIsVideoToggled(true);
    }
  }, []);

  useEffect(()=> {
    if (typeof window?.MathJax !== "undefined") {
      window.MathJax.typeset()
    }
  },[activity]);

  useEffect(() => {
    if (isChallengeSolved) {
      setIsMoreTimeRequested(false);
    }
  }, [isChallengeSolved]);

  const moreTimeButtonStyle = {
    backgroundColor: "white",
    border: `1px solid ${colors.nightlyWoods500}`,
    borderRadius: "20px",
    color: colors.nightlyWoods500,
    display: "flex",
    fontWeight: 500,
    padding: "8px 16px"
  };

  const disabledMoreTimeButtonStyle = {
    backgroundColor: colors.nightlyWoods100,
    cursor: "not-allowed",
    pointerEvents: "auto"
  };

  const TimerComponent = <>
    {timerTimeRemaining && timerTimeRemaining > 1 && (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          fontSize: "1.2rem",
          borderRadius: 12,
          width: "350px"
        }}
      >
        <Timer timeRemaining={timerTimeRemaining}/>
        <span className="mr-3 ml-3">
                  {timerTimeRemaining} seconds
                </span>
        {isShowRequestMoreTimeButton && !isFirstGather && <Button
          disabled={isMoreTimeRequested}
          onClick={dispatchMoreTimeRequest}
          style={!isMoreTimeRequested ? moreTimeButtonStyle :
            {...moreTimeButtonStyle, ...disabledMoreTimeButtonStyle}
          }
          type="submit">
          <div style={{ display: "flex", gap: "8px" }}>
            <img src={IconPlusCircle} alt="plus" />
            Add Time
          </div>
        </Button>}
      </div>
    )}
  </>;

  return (
    <StyledFullscreen>
      <div style={{ background: colors.milkGlass500, display: "flex", minHeight: "100vh" }}>
        <div style={{ background: "white", position: "fixed", width: "405px", height: "100%" }}>
          <ConsensusLeftPanel
            isSolutionEvent={isSolutionEvent}
            isChallengeSolved={isChallengeSolved}
            timer={TimerComponent}
          />
        </div>
        {challenge && <div style={{
          display: "flex",
          flexGrow: 1,
          justifyContent: "center",
          marginLeft: "405px",
          marginRight: "50px" }}>
          <ConsensusActivityRender
            hideQuestion={hideQuestion}
            selectedIndex={selectedIndex}
            challenge={challenge}
            disableOptions={disableOptions}
            onMCAnswer={onMCAnswer}
            onORAnswer={onORAnswer}
            isMathJaxSet={true}
            isChallengeSolved={isChallengeSolved}
          />
        </div>}
      </div>
    <div style={{display: "none"}}>
      <VideosBar />
    </div>
  </StyledFullscreen>
  );
};

export const StyledFullscreen = styled(Fullscreen)`
  background: ${colors.milkGlass500};
`;
