import styled from "styled-components";
import { ToggleLocalCamButton, ToggleLocalMicButton } from "./ActionButton";
import { ToggleSettingsModalButton } from "./ToggleSettingsModalButton";
import { ToggleSessionInspectorButton } from "./ToggleSessionInspectorButton";
import { FeedbackButton } from "./FeedbackButton";
import { colors } from "utils";

export const ActionBar = ({ fullHeight, isShowSessionInspectorButton }) => {
  return (
    <Container fullHeight={fullHeight}>
      {isShowSessionInspectorButton && <ToggleSessionInspectorButton />}
      <FeedbackButton />
      <ToggleLocalCamButton />
      <ToggleLocalMicButton />
      <ToggleSettingsModalButton />
    </Container>
  );
};

const Container = styled.div`
  background-color: rgb(255, 255, 255);
  border: 1px ${colors.nightlyWoods950} solid;
  ${(props) => (props.fullHeight ? "height: 100%;" : "")}
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 100pc;
  max-height: 100%;
`;
