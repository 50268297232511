import * as Sentry from "@sentry/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { request } from "hooks/useOkoApi/useOkoApi";
import { colors, constants } from "utils";

export const Reports = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [downloadedItems, setDownloadedItems] = useState({});

  const downloadFile = async (path) => {
    setDownloadedItems((prevState) => ({
      ...prevState,
      [path]: "Downloading...",
    }));
    setIsLoading(true);
    await request(path, {
      method: "GET",
    }).then(async (response) => {
      const contentDisposition = response.headers.get("Content-Disposition");
      let filename = "downloaded_file.csv";
      if (contentDisposition) {
        const match = contentDisposition.match(/filename="(.+?)"/);
        if (match && match[1]) {
          filename = match[1];
        }
      }
      const blob = await response.blob();
      const a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(a.href);
      setDownloadedItems((prevState) => ({
        ...prevState,
        [path]: "Downloaded",
      }));
    }).catch((e) => {
      setIsLoading(false);
      setDownloadedItems((prevState) => ({
        ...prevState,
        [path]: "Error",
      }));
      console.error(e);
      Sentry.captureException(e);
    }).finally(() => setIsLoading(false));
  }
  const getQuestionsReport = async () => {
    await downloadFile(constants.CHALLENGES_REPORT_API_PATH);
  };
  const getQuestionsByStandardsReport = async () => {
    await downloadFile(constants.CHALLENGES_BY_STANDARD_REPORT_API_PATH);
  };
  const buttonStyle = { backgroundColor: colors.nightlyWoods500, border: "none" };
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "24px", width: "fit-content" }}>
      <div style={{ display: "flex", gap: "24px" }}>
        <Button disabled={isLoading} onClick={getQuestionsReport} style={buttonStyle}>
          Click to download questions report with all Multiple Choice and Open Response challenges.
        </Button>
        {downloadedItems[constants.CHALLENGES_REPORT_API_PATH] &&
          <strong style={{ alignSelf: "center" }}>
            {downloadedItems[constants.CHALLENGES_REPORT_API_PATH]}
          </strong>}
      </div>
      <div style={{ display: "flex", gap: "24px" }}>
        <Button disabled={isLoading} onClick={getQuestionsByStandardsReport} style={buttonStyle}>
          Click to download questions report, aggregated by standards, challenge types and statuses.
        </Button>
        {downloadedItems[constants.CHALLENGES_BY_STANDARD_REPORT_API_PATH] &&
          <strong style={{ alignSelf: "center" }}>
            {downloadedItems[constants.CHALLENGES_BY_STANDARD_REPORT_API_PATH]}
          </strong>}
      </div>
      {isLoading && <div style={{ alignSelf: "center", position: "fixed", top: "50%" }}>
        <FontAwesomeIcon
          icon={faSpinner}
          spin
          size="5x"
        />
      </div>}
    </div>
  );
};
