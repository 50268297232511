import { MeetActivity } from "components/pages/Session/Activity/activities/MeetActivity/MeetActivity";
import { Settings as MeetActivitySettings } from "components/pages/Session/Activity/activities/MeetActivity/Settings/Settings";
import { CalculatorActivity } from "components/pages/Session/Activity/activities/CalculatorActivity/CalculatorActivity";
import { Settings as CalculatorActivitySettings } from "components/pages/Session/Activity/activities/CalculatorActivity/Settings/Settings";
import { WaitingRoom } from "components/pages/Session/Activity/activities/WaitingRoom/WaitingRoom";
import { Settings as WaitingRoomSettings } from "components/pages/Session/Activity/activities/WaitingRoom/Settings/Settings";
import { ReadyRoom } from "components/pages/Session/Activity/activities/ReadyRoom/ReadyRoom";
import { Settings as ReadyRoomSettings } from "components/pages/Session/Activity/activities/ReadyRoom/Settings/Settings";
import { EmptyActivity } from "components/pages/Session/Activity/activities/EmptyActivity/EmptyActivity";
import { ConsensusActivity } from "components/pages/Session/Activity/activities/ConsensusActivity/ConsensusActivity";
import { IconSelectActivity } from "components/pages/Session/Activity/activities/IconSelectActivity/IconSelectActivity";
import {
  faCalculator,
  faCommentAlt,
  faSign,
  faLemon,
  faThumbsUp,
  faListAlt,
  faDog,
} from "@fortawesome/free-solid-svg-icons";

export const activities = [
  {
    component: MeetActivity,
    icon: faCommentAlt,
    label: "Meet",
    id: "meet",
    settings: MeetActivitySettings,
  },
  {
    component: CalculatorActivity,
    settings: CalculatorActivitySettings,
    label: "Calculator",
    id: "calculator",
    icon: faCalculator,
  },
  {
    component: WaitingRoom,
    label: "Billboard",
    icon: faSign,
    id: "waiting-room",
    settings: WaitingRoomSettings,
  },
  {
    component: ReadyRoom,
    label: "Ready Room",
    icon: faThumbsUp,
    id: "ready-room",
    settings: ReadyRoomSettings,
  },
  {
    component: EmptyActivity,
    label: "Empty Activity",
    icon: faLemon,
    id: "empty",
  },
  {
    component: ConsensusActivity,
    label: "Multiple Choice",
    icon: faListAlt,
    id: "multiple-choice",
  },
  {
    component: IconSelectActivity,
    label: "Icon Select",
    icon: faDog,
    id: "icon-select",
  },
];
