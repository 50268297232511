import "./session_inspector.css";
import { useRef, useState } from "react";
import { Button } from "@bootstrap-styled/v4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { useDataTrack } from "hooks/livekit/useDataTrack/useDataTrack";
import { colors } from "utils";

export const SessionInspector = ({ toggleSessionInspector }) => {
  const [events, setEvents] = useState([]);
  const addEvent = (newEvent) => {
    setEvents((prevEvents) => [...prevEvents, newEvent]);
  };
  useDataTrack((e) => {
    addEvent(e);
  });
  return (
    <Inspector events={events} toggleSessionInspector={toggleSessionInspector} />
  );
};

export const Inspector = ({ events, toggleSessionInspector }) => {
  const [expanded, setExpanded] = useState([]);
  const inspectorRef = useRef(null);

  const [inspectorStyles, setInspectorStyles] = useState({
    width: 600,
    height: 800,
    top: 100,
    left: 100,
  });
  const dragData = useRef({ dragging: false, resizing: false, resizeDir: null, mouseX: 0, mouseY: 0 });

  const toggleExpand = (index) => {
    setExpanded((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  const handleMouseDown = (e, action, direction = null) => {
    e.preventDefault();
    dragData.current = {
      dragging: action === "drag",
      resizing: action === "resize",
      resizeDir: direction,
      mouseX: e.clientX,
      mouseY: e.clientY,
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e) => {
    e.preventDefault();
    const { dragging, resizing, resizeDir, mouseX, mouseY } = dragData.current;
    const dx = e.clientX - mouseX;
    const dy = e.clientY - mouseY;

    if (dragging) {
      setInspectorStyles((prev) => ({
        ...prev,
        top: Math.max(prev.top + dy, 0),
        left: Math.max(prev.left + dx, 0),
      }));
    } else if (resizing) {
      const rect = inspectorRef.current.getBoundingClientRect();
      const minWidth = 200;
      const minHeight = 150;

      setInspectorStyles((prev) => {
        let newStyles = { ...prev };

        if (resizeDir.includes("right")) {
          newStyles.width = Math.max(rect.width + dx, minWidth);
        }
        if (resizeDir.includes("bottom")) {
          newStyles.height = Math.max(rect.height + dy, minHeight);
        }
        if (resizeDir.includes("left")) {
          newStyles.width = Math.max(rect.width - dx, minWidth);
          newStyles.left = Math.min(prev.left + dx, rect.right - minWidth);
        }
        if (resizeDir.includes("top")) {
          newStyles.height = Math.max(rect.height - dy, minHeight);
          newStyles.top = Math.min(prev.top + dy, rect.bottom - minHeight);
        }

        return newStyles;
      });
    }

    dragData.current.mouseX = e.clientX;
    dragData.current.mouseY = e.clientY;
  };

  const handleMouseUp = () => {
    dragData.current = { dragging: false, resizing: false, resizeDir: null, mouseX: 0, mouseY: 0 };
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  return (
    <div
      ref={inspectorRef}
      className="inspector"
      style={{
        position: "fixed",
        top: inspectorStyles.top,
        left: inspectorStyles.left,
        width: inspectorStyles.width,
        height: inspectorStyles.height,
      }}
    >
      <div
        className="inspector-header"
        onMouseDown={(e) => handleMouseDown(e, "drag")}
        style={{ cursor: "grab", backgroundColor: colors.blue100, padding: "10px", fontWeight: "bold" }}
      >
        <span>Session Inspector</span>
        <CloseInspectorButton
          onClick={toggleSessionInspector}
        >
          <FontAwesomeIcon size="lg" icon={faTimes} />
        </CloseInspectorButton>
      </div>

      <div className="inspector-content" style={{ overflow: "auto", padding: "10px", height: "calc(100% - 30px)" }}>
        {events.map((event, index) => (
          <div key={index} className="mb-2">
            <div
              className="inspector-event-header"
              onClick={() => toggleExpand(index)}
              style={{ cursor: "pointer", fontWeight: "bold" }}
            >
              {expanded.includes(index) ? "▼" : "▶"} {event.event_type}
            </div>
            {expanded.includes(index) && (
              <pre className="inspector-event-details">{JSON.stringify(event, null, 2)}</pre>
            )}
          </div>
        ))}
      </div>

      <div className="resize-handle top-left" onMouseDown={(e) => handleMouseDown(e, "resize", "top-left")}></div>
      <div className="resize-handle top-right" onMouseDown={(e) => handleMouseDown(e, "resize", "top-right")}></div>
      <div className="resize-handle bottom-left" onMouseDown={(e) => handleMouseDown(e, "resize", "bottom-left")}></div>
      <div className="resize-handle bottom-right" onMouseDown={(e) => handleMouseDown(e, "resize", "bottom-right")}></div>
      <div className="resize-handle left" onMouseDown={(e) => handleMouseDown(e, "resize", "left")}></div>
      <div className="resize-handle right" onMouseDown={(e) => handleMouseDown(e, "resize", "right")}></div>
      <div className="resize-handle top" onMouseDown={(e) => handleMouseDown(e, "resize", "top")}></div>
      <div className="resize-handle bottom" onMouseDown={(e) => handleMouseDown(e, "resize", "bottom")}></div>
    </div>
  );
};

const CloseInspectorButton = styled(Button)`
  right: 0px;
  top: 4px;
  position: absolute;
  border: none;
  border-color: transparent !important;
  color: #444 !important;
  background: transparent !important;
  opacity: 0.4;
  box-shadow: none !important;
`;
