import { constants } from "./general";

export const buildEmptyChallenge = (mcMode = false) => {
  const challenge = {};
  challenge.difficulty = 1;
  challenge.standards = null;
  challenge.comments = null;
  challenge.status = "active";
  challenge.prompt = "";
  challenge.spoken_prompt = null;
  challenge.solution_index = null;
  challenge.answer_options = mcMode ? [] : null;
  challenge.randomize_options = false;
  challenge.prompt_image_url = null;
  challenge.render_type = "fraction_legacy";
  challenge.source = "LEGACY";
  challenge.external_id = null;
  return challenge;
};

export const buildPreviewChallenge = (challenge) => {
  const previewChallenge = JSON.parse(JSON.stringify(challenge));
  const newAnswerOptions = [];
  previewChallenge?.answer_options?.forEach((option, index) => {
    newAnswerOptions[index] = {text: option, eligible: true};
  });

  if (newAnswerOptions.length) {
      previewChallenge.answer_options = newAnswerOptions;
  }
  
  return previewChallenge;
};

export const buttonIdsToStr = (buttonIds) => {
  if (buttonIds) {
    return buttonIds.map((i) => i.toString());
  }
  return [];
};

export const handleSaveChallengeError = (activity, errorDetails) => {
  if (Array.isArray(errorDetails)) {
    let relatedErrors = [];
    if (activity === constants.CONSENSUS_ENUM) {
      errorDetails.forEach((i) => {
        if (JSON.stringify(i).indexOf("MultipleChoice") > -1) {
          relatedErrors.push(i);
        }
      });
      return relatedErrors;
    } else if (activity === constants.CRAZY_CALCULATOR_ENUM) {
      errorDetails.forEach((i) => {
        if (JSON.stringify(i).indexOf("CrazyCalculator") > -1) {
          relatedErrors.push(i);
        }
      });
      return relatedErrors;
    }
  }
  return errorDetails;
};

export const CONSENSUS_VARIANT_OPTIONS = [
  { value: 'multiplechoice', label: 'Multiple Choice' },
  { value: 'openresponse', label: 'Open Response' }
];

export const CHALLENGE_QUALITY_LLM_PROMPT = "You will receive a list of challenges, where each challenge can be " +
  "one of the 2 types: MultipleChoice or OpenResponse.\nMultipleChoice challenge always has 'answer_options' and " +
  "'solution_index' props, and never has 'solution' prop.\nMultipleChoice challenge has only one correct answer " +
  "option which is referenced in 'solution_index' so the value of 'solution_index' is a number of an element in " +
  "'answer_options' array.\nOpenResponse challenges always have 'solution' prop and never have 'answer_options' or " +
  "'solution' props.\nThe question for each challenge is in 'prompt' prop.\nThere could be also 'prompt_image_url' " +
  "prop that has some graphical content related to the question.\nYour task is to analyze every challenge, and flag " +
  "any challenges that are either confusing, badly worded, badly formatted, have wrong expected solution, " +
  "don't have enough context (like there is no 'prompt_image_url' and the 'prompt' asks to provide the answer for x), " +
  "are in any language other than English, etc.\nIf 'prompt_image_url' is there, make an assumption that " +
  " the missing context is in the image. Respond with a JSON-formatted list of challenge ids ('id' prop from the request).\n" +
  "Include only those challenges that were flagged.\nInclude the reason for flagging in a new 'flagged_reason' prop " +
  "for each challenge.\nReturn an empty array if there were no flagged challenges.\nDon't include any chars like ` in " +
  "the response as they could make JSON serialization for later needs impossible.";

export const CHALLENGE_STATUS_OPTIONS = [
  { value: 'all', label: 'ALL' },
  { value: 'active', label: 'ACTIVE' },
  { value: 'inactive', label: 'INACTIVE' }
];

export const isOpenResponseSolutionEmpty = (value) => {
  return value === "" || value === "-";
};

export const validOpenResponseSolution = (input) => {
  /*
  Validate that the solution is a number with maximum 10 digits, with optional decimal point,
  and not more than 4 digits after the decimal point (still 10 digits maximum).

  Allow cases:
  - Empty string and minus sign, so the box can be cleared and negative numbers can be entered
  - Whole numbers ("123", "-12345"), up to 10 digits total
  - Numbers with decimal point ("123.45", "-98765.4321"), up to 4 numbers after the decimal point
  - Numbers with a trailing dot ("123.", "-5678."), so the dot can be entered in the box in the first place
  */
  if (isOpenResponseSolutionEmpty(input)) return true;

  // Check for valid number pattern using regex (basic structure, no length limit yet)
  if (!/^(-?\d*\.?\d*)$/.test(input)) return false;

  // Remove the minus sign if present
  const isNegative = input.startsWith("-");
  const numStr = isNegative ? input.slice(1) : input; // Remove "-" for length check

  // Split into integer and decimal parts
  const parts = numStr.split(".");
  const integerPart = parts[0] || ""; // Ensure there's at least an empty string
  const decimalPart = parts[1] || ""; // Ensure there's at least an empty string

  // Ensure there's no more than one zero before the decimal point
  if (integerPart.match(/^0{2,}$/)) return false;

  // Total digits should not exceed 10
  if (integerPart.length + decimalPart.length > 10) return false;

  // Ensure decimal part has at most 4 digits
  if (decimalPart.length > 4) return false;

  return true;
};

export const addLeadingZeroIfDot = (input) => {
  // Check if the input starts with a minus sign
  const isNegative = input.startsWith("-");

  // If it starts with a minus sign, remove it temporarily
  if (isNegative) {
    input = input.slice(1);
  }

  // If the input starts with a dot, add a leading zero
  if (input.startsWith(".")) {
    // Reattach the negative sign if the number was negative
    if (isNegative) {
      return "-0" + input;
    }
    return "0" + input;
  }

  // Remove all leading zeros in the integer part (before the decimal)
  input = input.replace(/^0+(?=\d)/, '');

  // Reattach the negative sign if the number was negative
  if (isNegative) {
    input = "-" + input;
  }

  return input; // Otherwise, return the input as is
};

export const removeTrailingZeros = (input) => {
  // Remove trailing zeros after the decimal point and remove decimal point if it's the last character
  return input.replace(/(\.\d*?)0+$/, '$1').replace(/\.$/, '');
};
