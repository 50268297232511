import { useDocumentData } from "react-firebase-hooks/firestore";
import { useSessionActivity } from "hooks/useSessionActivity/useSessionActivity";
import {
  useFirebaseRef as useSessionFirebaseRef,
  useSessionContext,
} from "hooks/useSession";

export const useFirebaseRef = (activityId) => {
  const { session } = useSessionContext();
  const sessionId = session?.id || "SESSIONNOTFOUND";
  const ref = useSessionFirebaseRef(sessionId);
  const { activityId: sessionActivityId } = useSessionActivity();

  return ref?.collection(`activities`)?.doc(activityId || sessionActivityId);
};

export const useActivity = (activityId) => {
  const { session } = useSessionContext();
  const sessionRef = useSessionFirebaseRef(session?.id || "SESSIONNOTFOUND");
  const ref = useFirebaseRef(activityId);
  const [activity, loading, error] = useDocumentData(ref);

  const update = (data, id) => {
    let r = id ? sessionRef.collection(`activities`)?.doc(id) : ref;

    return r.set(data, { merge: true });
  };

  return { activity, loading, error, update, session };
};
