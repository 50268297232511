import { useState } from "react";
import styled from "styled-components";
import { Fullscreen } from "components/shared/Fullscreen/Fullscreen";
import { Badge, ListGroupItemHeading, Button, H5 } from "@bootstrap-styled/v4";
import { useActivity } from "hooks/useActivity/useActivity";
import { texts } from "config/texts";
import { useMembers } from "hooks/livekit/useMembers/useMembers";
import { useUser } from "hooks/useUser/useUser";
import { useSessionActivity } from "hooks/useSessionActivity/useSessionActivity";
import { useSessionContext } from "hooks/useSession";
import { request } from "hooks/useOkoApi/useOkoApi";
import { useFirebase } from "hooks/useFirebase";
import { VideosBar } from "components/pages/Session/shared/VideosBar/VideosBar";
import { colors } from "utils";
import PaperPlane from "assets/PaperPlane.gif";

export const WaitingRoom = () => {
  const firebase = useFirebase();
  const { session } = useSessionContext();
  const { change: changeActivity } = useSessionActivity();
  const { update: updateActivity } = useActivity();
  const { activity, loading } = useActivity();
  const { members } = useMembers({ includeObservers: false });
  const [hasVoted, setHasVoted] = useState(false);

  const onStart = async () => {
    const res = await request(`session/${session?.id}/vote_to_start`);
    setHasVoted(true);

    if (res?.votes_so_far === res?.total_votes_required) {
      changeActivity({ id: "waiting-room" });
      updateActivity(
        {
          title: texts.waitingRoomDefaultTitle,
          showParticipants: false,
          showParticipantVideos: false,
        },
        "waiting-room"
      );
    }
  };

  return (
    <StyledFullscreen>
      <>
        {!loading && (
          <div style={{ display: "flex", flexDirection: "column", fontSize: "36px", fontWeight: "500", gap: "64px" }}>
              <span>
                {activity?.title === undefined
                  ? texts.waitingRoomDefaultTitle
                  : activity?.title}
              </span>
            {!activity?.hideLoadingIndicator && (
              <div style={{ alignSelf: "center" }}>
                <img src={PaperPlane} />
              </div>
            )}
            {activity?.showExitButton && (
              <Button
                onClick={async () => {
                  await firebase.auth().signOut();
                  window.location.href = "https://okolabs.ai/";
                }}
                className="mt-3"
                size="lg"
              >
                OK
              </Button>
            )}
            {activity?.showParticipants && (
              <div>
                <ListGroupItemHeading>
                  {members.length <= 1 && "Waiting for others to join..."}
                  {members.length > 1 &&
                    "Is this everyone in your OKO group?"}
                </ListGroupItemHeading>
                <H5>
                  {members.map((m) => (
                    <Badge className="mr-2" key={m.participant.id}>
                      <Name participant={m.participant} />
                    </Badge>
                  ))}
                </H5>
                {members.length > 1 && !hasVoted && (
                  <Button onClick={onStart} className="mt-3">
                    Yes that's everyone - let's start
                  </Button>
                )}
              </div>
            )}
          </div>
        )}

        <div
          style={{
            width: "96vw",
            marginTop: 10,
            opacity: activity?.showParticipantVideos ? 1 : 0,
          }}
        >
          <VideosBar />
        </div>
      </>
    </StyledFullscreen>
  );
};

const StyledFullscreen = styled(Fullscreen)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${colors.milkGlass500};
`;

const Name = ({ participant }) => {
  const { user } = useUser(participant.userId);
  return <span>{user?.displayName}</span>;
};
