import { faBug, faSpinner } from "@fortawesome/free-solid-svg-icons";
import * as Sentry from "@sentry/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "@bootstrap-styled/v4";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { colors } from "utils";
import { ActionButton } from "./ActionButton";
import { request } from "hooks/useOkoApi/useOkoApi";
import { useSessionContext } from "hooks/useSession";

export const FeedbackButton = ({ style }) => {
  const { session } = useSessionContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [feedbackType, setFeedbackType] = useState();
  const [detailsText, setDetailsText] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleFeedbackType = (e) => {
    setFeedbackType(e.value);
  };

  const handleSubmit = async () => {
    if (!feedbackType || !detailsText) return;
    setIsLoading(true);
    await request("feedback/submit_custom_feedback", {
      payload: {
        feedback_type: feedbackType,
        details: detailsText,
        session_id: session?.id ?? null
      },
    }).then(() => {
      setIsModalOpen(false);
      setFeedbackType(null);
      setDetailsText(null);
    }).catch((e) => {
      console.error(e);
      Sentry.captureException(e);
    }).finally(()=> setIsLoading(false));
  };

  const handleClose = () => {
    if (isLoading) return;
    setIsModalOpen(false);
  };

  const getFeedbackTypeOptions = () => {
    return (
      [
        { label: "Suggestion/Feature Request", value: "suggestion" },
        { label: "Bug/Performance Issue", value: "bug" },
      ]
    );
  };

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      borderColor: "#858B87",
      width: "260px"
    }),
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? colors.gray100 : undefined,
        padding: "8px 12px",
        color: "#1D1F23",
        ':active': {
          ...styles[':active'],
          backgroundColor: isSelected ? 'black' : 'white',
        },
      };
    },
    valueContainer: (styles) => ({
      ...styles,
      padding: "4px 6px" })
  };

  return (
    <>
      <ActionButton
        onClick={() => setIsModalOpen(true)}
        style={style}
        icon={faBug}
        tooltip={"Report issue / provide feedback"}
      />
      <Modal show={isModalOpen}>
        {isLoading && <div style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 9999
        }}>
          <FontAwesomeIcon
            icon={faSpinner}
            spin
            size="5x"
          />
        </div>}
        <Modal.Header><span style={{ fontSize: "20px" }}>Provide feedback or report an issue</span></Modal.Header>
        <Modal.Body>
          <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <Select
              menuPlacement={"auto"}
              options={getFeedbackTypeOptions()}
              defaultValue={null}
              placeholder="Select feedback type..."
              onChange={handleFeedbackType}
              styles={selectStyles}
              value={getFeedbackTypeOptions().filter((i) => feedbackType === i.value)}>
            </Select>
            {feedbackType && <Input placeholder="Please provide details here..."
                                  maxLength={500}
                                  type="textarea"
                                  style={{ height: "100px" }}
                                  onChange={(e) => setDetailsText(e.target.value)}
                                  value={detailsText} />}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleSubmit}
            style={{
              backgroundColor: colors.pink500,
              border: "none",
              borderRadius: "20px",
              fontSize: "16px",
              cursor: isLoading || !feedbackType || !detailsText ? "not-allowed" : "pointer",
              opacity: isLoading || !feedbackType || !detailsText ? 0.5 : 1
            }}
          >
            Submit
          </Button>
          <Button
            onClick={handleClose}
            style={{
              backgroundColor: "white",
              border: `1px solid ${colors.nightlyWoods500}`,
              borderRadius: "20px",
              color: colors.nightlyWoods500,
              fontSize: "16px",
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
