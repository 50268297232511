import React, { useState, useEffect } from "react";
import {
  faWifi,
  faExclamationTriangle,
  faGlobe,
  faVideo,
  faVolumeHigh
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { Track } from "livekit-client";
import { useSessionActivity } from "hooks/useSessionActivity/useSessionActivity";
import useVideoContext from "hooks/livekit/useVideoContext/useVideoContext";
import { request } from "hooks/useOkoApi/useOkoApi";
import { colors } from "utils";

const NetworkIndicatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const NetworkIndicator = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${props => props.color};
  cursor: pointer;
  position: relative;

  &::after {
    content: "${props => props.tooltip}";
    position: absolute;
    top: 50%;
    right: calc(100% + 8px);
    transform: translateY(-50%);
    background-color: ${colors.nightlyWoods500};
    color: ${colors.milkGlass500};
    padding: 6px;
    border-radius: 5px;
    font-size: 16px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
    z-index: 1000;
  }

  &:hover::after {
    opacity: 1;
    visibility: visible;
  }
`;

const SpeedText = styled.span`
  font-size: 9px;
  font-weight: bold;
  color: white;
  margin-top: 1px;
`;

export const NetworkMonitor = ({ style, updateMessage }) => {
  const { session } = useSessionActivity();
  const { room } = useVideoContext();
  const [navigatorSpeed, setNavigatorSpeed] = useState();
  const [webRTCVideoQuality, setWebRTCVideoQuality] = useState();
  const [webRTCAudioQuality, setWebRTCAudioQuality] = useState();
  const [lastMessageTime, setLastMessageTime] = useState(0);

  useEffect(() => {
    const updateConnectionStatus = async () => {
      try {
        const currentTime = Date.now();
        const messagingInterval = 60000;

        let downloadSpeedResult = null;
        if (navigator.connection) {
          const downlinkSpeed = navigator.connection.downlink;
          downloadSpeedResult = downlinkSpeed.toFixed(1);
          setNavigatorSpeed(downloadSpeedResult);

          if (downloadSpeedResult <= 1 && currentTime - lastMessageTime >= messagingInterval) {
            updateMessage("Poor network connection. Your experience may be affected.");
            setLastMessageTime(currentTime);
          } else if (downloadSpeedResult > 1) {
            updateMessage("");
          }
        }

        const videoRTCQuality = getWebRTCQuality(room.localParticipant.getTrackPublication(Track.Source.Camera));
        const audioRTCQuality = getWebRTCQuality(room.localParticipant.getTrackPublication(Track.Source.Microphone));
        setWebRTCVideoQuality(videoRTCQuality);
        setWebRTCAudioQuality(audioRTCQuality);

        if ((videoRTCQuality <= 50 || audioRTCQuality <= 50) && currentTime - lastMessageTime >= messagingInterval) {
          updateMessage("Poor video/audio quality. Please check your network connection.");
          setLastMessageTime(currentTime);
        }

        if (session) {
          await request(`user-data/${session.id}/bandwidth`, {
            payload: {
              navigator_downlink: downloadSpeedResult,
              video_delivery_rate: videoRTCQuality,
              audio_delivery_rate: audioRTCQuality
            },
          }).catch((e) => {
            console.error("Error in bandwidth API request: ", e);
          });
        }
      } catch (e) {
        console.error("Error in updateConnectionStatus: ", e);
      }
    };

    const getWebRTCQuality = (trackPublication) => {
      if (!room) {
        return;
      }
      if (!trackPublication) {
        return;
      }
      const localTrack = trackPublication.track;
      if (!localTrack) {
        return;
      }
      const stats = localTrack.mediaStreamTrack.stats;
      if (!stats) {
        return;
      }
      const { deliveredFrames, totalFrames } = stats;
      const frameDeliveryRate = deliveredFrames / totalFrames;
      return (frameDeliveryRate * 100).toFixed(0);
    };

    updateConnectionStatus();
    const intervalId = setInterval(updateConnectionStatus, 10000);
    navigator.connection?.addEventListener("change", updateConnectionStatus);

    return () => {
      clearInterval(intervalId);
      navigator.connection?.removeEventListener("change", updateConnectionStatus);
    };
  }, [room, updateMessage, lastMessageTime]);

  const getNetworkStatus = (speed) => {
    if (speed === null) return { color: "#808080", icon: faExclamationTriangle, text: "Unknown" };
    if (speed <= 1) return { color: "#FF4136", icon: faExclamationTriangle, text: "Poor" };
    if (speed <= 5) return { color: "#FF851B", icon: faWifi, text: "Fair" };
    return { color: "#2ECC40", icon: faGlobe, text: "Good" };
  };

  const getRTCStatus = (quality) => {
    if (quality === null) return { color: "#808080", text: "Unknown" };
    if (quality <= 50) return { color: "#FF4136", text: "Poor" };
    if (quality <= 60) return { color: "#FF851B", text: "Fair" };
    return { color: "#2ECC40", text: "Good" };
  };

  return (
    <NetworkIndicatorContainer style={style}>
      <NetworkIndicator
        color={getNetworkStatus(navigatorSpeed).color}
        tooltip={`Navigator Bandwidth: ${getNetworkStatus(navigatorSpeed).text}`}
      >
        <FontAwesomeIcon icon={getNetworkStatus(navigatorSpeed).icon} color="white" size="xs" />
        <SpeedText>{navigatorSpeed ? `${navigatorSpeed}M` : "?"}</SpeedText>
      </NetworkIndicator>
      <NetworkIndicator
        color={getRTCStatus(webRTCVideoQuality).color}
        tooltip={`Video WebRTC: ${getRTCStatus(webRTCVideoQuality).text}`}
      >
        <FontAwesomeIcon icon={faVideo} color="white" size="xs" />
        <SpeedText>{webRTCVideoQuality ? `${webRTCVideoQuality}%` : "?"}</SpeedText>
      </NetworkIndicator>
      <NetworkIndicator
        color={getRTCStatus(webRTCAudioQuality).color}
        tooltip={`Audio WebRTC: ${getRTCStatus(webRTCAudioQuality).text}`}
      >
        <FontAwesomeIcon icon={faVolumeHigh} color="white" size="xs" />
        <SpeedText>{webRTCAudioQuality ? `${webRTCAudioQuality}%` : "?"}</SpeedText>
      </NetworkIndicator>
    </NetworkIndicatorContainer>
  );
};
