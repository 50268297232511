import { RoomOptions, VideoPresets } from "livekit-client";

/**
 * LiveKit Room connection options: {@link RoomOptions}
 * or {@link https://docs.livekit.io/client-sdk-js/interfaces/RoomOptions.html}
 */
export default function useRoomOptions() {
  return {
    publishDefaults: {
      videoEncoding: {
        ...VideoPresets.h360.encoding,
        frameRate: 20
      }
    }
  };
};
