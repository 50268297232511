import { useState } from "react";
import styled from "styled-components";
import { Fullscreen } from "components/shared/Fullscreen/Fullscreen";
import { Button } from "@bootstrap-styled/v4";
import { useActivity } from "hooks/useActivity/useActivity";
import { useMembers } from "hooks/livekit/useMembers/useMembers";
import { useSessionActivity } from "hooks/useSessionActivity/useSessionActivity";
import { useSessionContext } from "hooks/useSession";
import { request } from "hooks/useOkoApi/useOkoApi";
import { VideosBar } from "components/pages/Session/shared/VideosBar/VideosBar";
import { texts } from "config/texts";
import OKOCoreLogo from "assets/OKO_Core_Logotype2.svg";
import DolphinAnimation from "assets/dolphinAnimation.gif";
import { colors } from "utils";

export const ReadyRoom = () => {
  const { session } = useSessionContext();
  const { change: changeActivity } = useSessionActivity();
  const { update: updateActivity } = useActivity();
  const { members } = useMembers({ includeObservers: false });
  const [hasVoted, setHasVoted] = useState(false);

  const onStart = async () => {
    const res = await request(`session/${session?.id}/vote_to_start`);
    setHasVoted(true);

    if (res?.votes_so_far === res?.total_votes_required) {
      changeActivity({ id: "waiting-room" });
      updateActivity(
        {
          title: texts.waitingRoomDefaultTitle,
          showParticipants: false,
          showParticipantVideos: false,
        },
        "waiting-room"
      );
    }
  };

  const SingleParticipantView = () => {
    return (
      <div style={{ alignItems: "center", display: "flex", flexDirection: "column", gap: "24px" }}>
        <div style={{fontSize: "36px", fontWeight: "600", lineHeight: "24px" }}>
          You’re the first to arrive. Wait a sec...
        </div>
        <div style={{fontSize: "24px", fontWeight: "400" }}>
          Turn on your camera and mic if you’re muted or can’t see yourself.
        </div>
        <div style={{ width: "96vw" }}>
          <VideosBar />
        </div>
        <div>
          <img src={DolphinAnimation} />
        </div>
      </div>
    );
  };

  const MultipleParticipantsView = () => {
    return (
      <div style={{ alignItems: "center", display: "flex", gap: "80px", justifyContent: "center", width: "80vw" }}>
        <div style={{ alignItems: "center", display: "flex", flexDirection: "column", gap: "32px", height: "200px", width: "428px" }}>
          <span style={{ fontSize: "36px", fontWeight: 500, textAlign: "center" }}>Can you see and hear everyone in your group?</span>
          {!hasVoted && (
            <div style={{ alignItems: "center", display: "flex", flexDirection: "column", gap: "12px", width: "200px" }}>
              If yes, click this button
              <BeginButton onClick={onStart}>Let's Begin</BeginButton>
            </div>
          )}
        </div>
        <div style={{ alignItems: "center", display: "flex", flexDirection: "column", gap: "24px", width: "600px" }}>
          <div style={{ fontSize: "16px", fontWeight: "400" }}>
            Turn on your camera if you can’t see yourself below.
          </div>
          <div style={{ width: "100%" }}>
            <VideosBar />
          </div>
        </div>
      </div>
    );
  };

  return (
    <StyledFullscreen>
      <>
        <div style={{ left: "24px", position: "absolute", top: "24px" }}>
          <img src={OKOCoreLogo} />
        </div>
        {members.length >= 2 ? (<MultipleParticipantsView />) : ( <SingleParticipantView />)}
      </>
    </StyledFullscreen>
  );
};

const StyledFullscreen = styled(Fullscreen)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${colors.milkGlass500};
`;

const BeginButton = styled(Button)`
  background: ${colors.pink500} !important;
  border: none !important;
  border-radius: 200px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  height: 48px;
  word-wrap: break-word !important;
  width: 100%;
`;

